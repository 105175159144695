// import { useNavigate } from 'react-router-dom';
import { Outlet, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../Header"
import { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import CaseManagement from "./CaseManagement";
import CaseDetails from "./CaseDetails";
import WorkplanReport from "./WorkplanReport";
import PreliminaryInvestigation from "./PreliminaryInvestigation";
import { refreshToken } from "../../request";
import FullInvestigation from "./FullInvestigation";

const sidebarItems = [
  {
    title: "Dashboard",
    icon: "dashboard",
  },
  {
    title: "Case Management",
    icon: "folder_open",
  },
  {
    title: "Reports",
    icon: "article",
  },
  {
    title: "Workplan",
    icon: "",
  },
  {
    title: "Preliminary Investigation",
    icon: "",
  },
  {
    title: "Full Investigation",
    icon: "",
  },
];

const Sidebar = (props) => {
    const { updatePage, page } = props;
  
    return (
      <div className="sidenav">
        <ul>
          {sidebarItems.map((item, index) => (
            <li
              key={index}
              className={page === item.title ? "nav-item active" : "nav-item"}
              onClick={() => updatePage(item.title)}
            >
              <span className="material-symbols-rounded">{item.icon}</span>
              {item.title.includes("Workplan") || item.title.includes("Preliminary Investigation") || item.title.includes("Full Investigation") ? (
                <span className="small-circle"></span>
              ) : null}
              {item.title}

              {item.title === "Reports" && <span className="material-symbols-rounded right-item">{"arrow_drop_down"}</span>}
            </li>
          ))}
        </ul>
      </div>
    );
}  

const Investigator = () => {
    const navigate = useNavigate();
    const url = new URL(window.location);
    const urlParams = new URLSearchParams(url.search);

    const [isAllowed, setIsAllowed] = useState(false)
    const [page, setPage] = useState(
      (localStorage.getItem("page") === null || !localStorage.getItem("page").includes(sidebarItems.map((item) => item.title))) ? "Dashboard"
      : localStorage.getItem("page"))

    const [params, setParams] = useState({})
    const updatePage = (page) => { if(page !== "Approvals"){
      localStorage.setItem("page", page)
      setPage(page)
      
    }}    

    refreshToken().then((res) => {
      if(res.status){
        if(!res.role.includes("investigator")) navigate('/error')
        else setIsAllowed(true)
      }
    })

    useEffect(() => {
      localStorage.clear()
      if(urlParams.get('page')) setPage(urlParams.get('page'))
      if(urlParams.get('page') && urlParams.get('id')) {
        setPage(urlParams.get('page'))
        setParams({id: parseInt(urlParams.get('id')), origin: "Case Management"})
      }
      navigate("/investigator") // eslint-disable-next-line
    }, [])
    
    return (
        <>
            {isAllowed &&
              <>
                <Header />
                <Sidebar page={page} updatePage={updatePage} />
                <div className="container">
                    {page=== "Dashboard" && <Dashboard updatePage={updatePage} page_title={page} setParams={setParams} />}
                    {page=== "Case Management" && <CaseManagement updatePage={updatePage} page_title={page} setParams={setParams}/>}
                    {page=== "Case Details" && <CaseDetails updatePage={updatePage} page_title={page} params={params}  />}
                    {page=== "Workplan" && <WorkplanReport page_title={page} />}
                    {page=== "Preliminary Investigation" && <PreliminaryInvestigation page_title={page} />}
                    {page=== "Full Investigation" && <FullInvestigation page_title={page} />}
                </div>              
                <Outlet />
              </>
              }
            <ToastContainer/>
        </>
    )
}

export default Investigator