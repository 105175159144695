import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect } from "react";
import { ACCESS_TOKEN, makePostRequest, makePutRequest, USERDETAILS } from '../../request';
import loading from "../../assets/images/loading.gif"
import Modal from 'react-modal';
// import { useNavigate } from 'react-router-dom';

const FullInvestigation = (props) => {
    // const navigate = useNavigate()
    const {page_title} = props
    const [formData, setFormData] = useState({
        comment: ""
    });
    const [isOpenReview, setIsOpenReview] = useState(false);
    const [isOpenChat, setIsOpenChat] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); //limit
    const [totalItems, setTotalItems] = useState(null);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    }; 

    const handleOpenModal = (modal) => {
        switch(modal){
            case "review":
                setIsOpenReview(true)
                break

            case "chat":
                setIsOpenChat(true)
                break

            default:
                break
        }
    }

    const handleCloseModal = (modal) => {
        switch(modal){
            case "review":
                setIsOpenReview(false)
                break

            case "chat":
                setIsOpenChat(false)
                break

            default:
                break
        }
    }

    const [cases, setCases] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchValue, setSearchValue] = useState(null)
    const getAllCases = () => {
        setIsLoading(true)
        var request = {
            what: "getAllCases",
            data: { 
                pagination: {
                    skip: (currentPage - 1) * itemsPerPage,
                    limit: itemsPerPage 
                },
                desired_statuses: ["Full Investigation Ongoing", "Full Investigation Report Under Review"]
            }
        };
        
        makePostRequest(request)
            .then((response) => {
                setIsLoading(false)
                const res = response.data.cases

                setTotalItems(response.data.total_records)
                if(searchValue) setCases(res.filter((item) => item.caseno.toLowerCase().includes(searchValue.toLowerCase()) 
                    || item.title.toLowerCase().includes(searchValue.toLowerCase())))
                else setCases(res)

            })
            .catch((error) => {
                toast.error(error.msg)
                // if(error.statuscode === 401) navigate('/')
            });
    }

    const handleSearch = (event) => {
        const value = event.target.value
        if(!value) setSearchValue(null)
    }

    const formatDateTime = (datetime) => {
        const dateString = datetime;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-GB', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit',
            minute:'2-digit',
            hour12: false 
        });
        return formattedDate.replace(/,/g, '');;
    }

    const handleChange = (event) => {
        const { name, value } = event.target //get data form each input on change
        setFormData(values => ({...formData, [name]: value})) //set retrieved values to "formData" object
    }

    const [reportId, setReportId] = useState(null)
    const [reportName, setReportName] = useState(null)
    const respondToReport = (value) => {        
        if(formData.comment === "" && value === "rejected") toast.error("Kindly provide your reason for rejection in the comment field")
        else {
            var request = {
                what: "respondToReport",
                id: reportId,
                data: {
                    status: value, //approved or rejected
                    comment: formData.comment,
                },                
            };    
    
            makePutRequest(request)
                .then((response) => {    
                    toast.success(response.msg)
                    handleCloseModal('review')  // close modal
                    setReportId(null)  
                    setReportName(null) 
                    getAllCases() 
                })
                .catch((error) => {toast.error(error.msg); });
        }
    }

    const handleDownloadClick = async (filename) => {
        try {
            document.getElementById("download").innerHTML = "Downloading..."
          // Fetch the file from the API
          const response = await fetch(process.env.REACT_APP_SERVER_URL+"/downloadreport?filename="+filename.split(".")[0], {
            method: 'POST',
            headers: {
                'Authorization':'Bearer ' + ACCESS_TOKEN
            }});
          const blob = await response.blob();
    
          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);

          // Open the Blob URL in a new window
        //   window.open(blobUrl, '_blank');
    
          // Create an anchor element
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = filename; // Set the desired file name
    
          // Trigger the click event to initiate the download
          a.click();
    
          // Cleanup: Revoke the Blob URL
          URL.revokeObjectURL(blobUrl);
          document.getElementById("download").innerHTML = "Download Report"
        } catch (error) {
          toast.error('Error downloading file:', error); document.getElementById("download").innerHTML = "Download Report"
        }
    };

    const row = cases.map((singleCase) => (
        singleCase.reports.filter((report)=>report.status==="pending"&&report.casetype === "Full Investigation Report").map((report) => (
          <tr key={report.id}>
            <td className='no-textwrap'>{singleCase.caseno}</td>
            <td>{report.casetitle}</td>
            <td>{formatDateTime(report.createdAt)}</td>
            <td className='capitalize'><span className={"status "+report.status}>{report.status}</span></td>
            <td className="actions">
                    <button 
                        onClick={() => {handleOpenModal("review"); setReportId(report.id); setReportName(report.filename)}} className="custom-button blue-outline">Review</button>
                    <span onClick={() => {handleOpenModal("chat"); setCaseComments(singleCase.case_comment)}} className="material-symbols-rounded chat-button">forum</span>
            </td>
          </tr>
        ))
    ));  

    const copyToClipboard = (text) => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = text;
    
        // Make the textarea non-editable to avoid focus and activation
        tempTextArea.setAttribute("readonly", "");
        tempTextArea.style.position = "absolute";
        tempTextArea.style.left = "-9999px";
    
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
    
        try {
            // Copy the text to the clipboard
            document.execCommand("copy");
            toast.success("Email copied to clipboard: " + text);
        } catch (err) {
            toast.error("Unable to copy email to clipboard: " + err);
        } finally {
            document.body.removeChild(tempTextArea);
        }
    }
    
    const [caseComments, setCaseComments] = useState([])
    const comments = caseComments.filter((comment)=>comment.commentype==="Full Investigation Report").map((row, index) => {
        return (
            <div key={index} className={USERDETAILS.emailaddress === row.userdetails.split(';')[2] ? 'chat-dialog right' : 'chat-dialog left'}>                    
                <div className='inner'>
                    <p className='title'><span className='capitalize'>{row.userdetails.split(';')[0]} {row.userdetails.split(';')[1]}</span> - <span className='uppercase'>{row.userdetails.split(';')[3]}</span></p>
                    <p className='link' onClick={() => copyToClipboard(row.userdetails.split(';')[2])}>Copy Email</p>
                </div> 
                <p className='body'>{row.comment}</p>
                <p className='date'>{formatDateTime(row.createdAt)}</p>
            </div>
        )
    })

    useEffect(() => {
        if(ACCESS_TOKEN) getAllCases() 
        // eslint-disable-next-line
    }, [currentPage, itemsPerPage, searchValue])    

    return (
        <>
             <div className="main">
                <h3 className="page_title">{"Approvals / " + page_title }</h3>     
                <div className="main-inner">
                    <div className="main-inner-top">
                        <div className="search-container left-item">
                            <span className="material-symbols-rounded">search</span>
                            <input title='Saerch by case id, title' id="search" placeholder="Search Cases" onKeyUp={handleSearch} />
                        </div>
                        <button className="custom-button blue left-item mr-2" onClick={() => 
                            setSearchValue(document.getElementById("search").value)}>Search</button>
                        <div className='right-item'></div>
                    </div>
                    <div className="table-container custom">
                        <table>
                            <thead>
                                <tr>
                                    <th>Case ID</th>
                                    <th>Case Title</th>
                                    <th>Upload Date</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? null : row }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                <img src={loading} alt="loading" />
                                <p>Loading data...</p>
                            </div> : null}
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-group">
                            <div>
                                <select onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}>
                                    <option value="10">Show 10 Entries</option>
                                    <option value="25">Show 25 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                </select>
                                <p className="page-num">Page {currentPage} of {totalPages}</p>

                            </div>
                            <div className="pagination">
                                <button className="page-item" onClick={handlePrevious} disabled={currentPage === 1} >Previous</button>
                                {
                                    getPageNumbers()
                                        .slice(0, Math.min(3, getPageNumbers().length)) // Limit the range of the array to the first 2 elements
                                        .map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                                onClick={() => setCurrentPage(pageNumber)}
                                            >{pageNumber}</button>
                                        ))                                        
                                }
                                {getPageNumbers().length > 3 && <button className='page-item'>...</button>}

                                {getPageNumbers().length > 3 && 
                                <button
                                    className={`page-item ${currentPage === getPageNumbers().length ? 'active' : ''}`}    
                                >
                                    {getPageNumbers().length}
                                </button>}
                                <button className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    </div> 
                </div>                 
            </div>

            <Modal isOpen={isOpenReview} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Review Case</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("review")}>close</span>
                </div>
                <button id='download' className="custom-button blue-outline mb-2" onClick={() => handleDownloadClick(reportName)}>
                    Download Report</button>

                <br /><label>Comment</label>
                <div className="modal-body mt-1">
                    <textarea name='comment' onChange={handleChange} placeholder="Leave a comment..."></textarea>
                    <p className='alert alert-warning d-flex'  style={{gap: "8px", alignItems: "start"}}><svg height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill='#664d03' d="M569.5 440C588 472 564.8 512 527.9 512H48.1c-36.9 0-60-40.1-41.6-72L246.4 24c18.5-32 64.7-32 83.2 0l239.9 416zM288 354c-25.4 0-46 20.6-46 46s20.6 46 46 46 46-20.6 46-46-20.6-46-46-46zm-43.7-165.3l7.4 136c.3 6.4 5.6 11.3 12 11.3h48.5c6.4 0 11.6-5 12-11.3l7.4-136c.4-6.9-5.1-12.7-12-12.7h-63.4c-6.9 0-12.4 5.8-12 12.7z"/></svg>
                   <span>Please make sure not to include any confidential or sensitive information in the comment field.</span></p>
                </div>
                <div className="modal-footer bt-1">
                    <button className="custom-button red-outline"
                        onClick={() => respondToReport("rejected")}>Reject</button>
                    <button type="submit" className="custom-button blue"
                        onClick={() => respondToReport("approved")}>Approve</button> 
                </div>
            </Modal> 

            <Modal isOpen={isOpenChat} style={customStyles} className="modal modal-6" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Chat History</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("chat")}>close</span>
                </div>
                <div className='chat-container'>
                    {comments.length < 1 ? <span>No Comments</span> : comments}
                </div>
            </Modal>
            <ToastContainer />
        </> 
    )
}

export default FullInvestigation