import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { ACCESS_TOKEN, USERDETAILS, makePostRequest, makePutRequest } from '../../request';
import loading from "../../assets/images/loading.gif"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
const filterOptions = createFilterOptions({ limit: 100 });

const UserManagement = (props) => {
    const {page_title} = props
    // const navigate = useNavigate()

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); //limit
    const [totalItems, setTotalItems] = useState(null);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);            
        }
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    useEffect(() => {
        // You can use the 'skip' value for your API calls or data fetching here
        getAllUsers() // eslint-disable-next-line
    }, [currentPage, itemsPerPage]);

    const [onboardUserModal, setOnboardUserModal] = useState(false)
    const [manageUserModal, setManageUserModal] = useState(false)
    const [confirmActivateDeactivateModal, setConfirmActivateDeactivateModal] = useState(false)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    // const [toggleOnboardType, setToggleOnboardType] = useState(true)

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        department: "",
        others: "",
    })
    const [errorData, setErrorData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        role: "",
        department: "",
        others: "",
    })

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    }; 

    const handleOpenModal = (modal) => {
        switch(modal){
            case "onboard_user":
                setOnboardUserModal(true)
                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    role: "",
                    department: "",
                    others: "",
                })
                setActivity(null)
                setIsApprove(null)
                setErrorData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    role: "",
                    department: "",
                    others: "",
                })
                setJoinedRoles(null)
                setSelectedRoles([])
                break

            case "activate_deactivate_user":
                setConfirmActivateDeactivateModal(true)
                setUserId(null)
                setActivity(null)
                setActivityName(null)
                setIsApprove(null)
                setActive(null)
                break

            case "delete_user":
                setConfirmDeleteModal(true)
                setUserId(null)
                setActivity(null)
                setIsApprove(null)
                setActive(null)
                break

            case "manage_user":
                setManageUserModal(true)
                setFormData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    role: "",
                    department: "",
                    others: "",
                })
                setActivity(null)
                setActivityName(null)
                setIsApprove(null)
                setActive(null)
                setUserId(null)
                setErrorData({
                    first_name: "",
                    last_name: "",
                    email: "",
                    role: "",
                    department: "",
                    others: "",
                })
                setJoinedRoles(null)
                setSelectedRoles([])

                break

            default:
                break
        }
    }
    const handleCloseModal = (modal) => {
        switch(modal){
            case "onboard_user":
                setOnboardUserModal(false)
                break

            case "activate_deactivate_user":
                setConfirmActivateDeactivateModal(false)
                break

            case "delete_user":
                setConfirmDeleteModal(false)
                break

            case "manage_user":
                setManageUserModal(false)
                break

            default:
                break
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target //get data form each input on change
        setFormData(values => ({...formData, [name]: value})) //set retrieved values to "formData" object  
    }

    const [joinedRoles, setJoinedRoles] = useState(null)
    const [selectedRoles, setSelectedRoles] = useState([]);
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            setSelectedRoles((prevSelectedRoles) => [...prevSelectedRoles, value]);
        } else {
            setSelectedRoles((prevSelectedRoles) =>
                prevSelectedRoles.filter((role) => role !== value)
            );
        }        
    };
    
    useEffect(() => {
        // Update formData.role whenever selectedRoles change
        const joinedValues = selectedRoles.join(', ');
        setFormData({ ...formData, role: selectedRoles });        
        setJoinedRoles(joinedValues) // get the roles as string 
        // eslint-disable-next-line
    }, [selectedRoles]);

    // const onFileChange = (event) => {
    //     let selectedFile = event.target.files[0]
    //     if(selectedFile) { 
    //         let file = selectedFile.name.split(".")
    //         if (file[1] !== "xlsx") toast.error("Attempted to upload an invalid file format. Please re-upload the correct file formats.")
    //         else setFormData(({...formData, file: selectedFile}))
    //     }
    // }

    useEffect(() => {
        if (formData.department !== "others") setErrorData({ ...errorData, others: '' })// eslint-disable-next-line
    }, [ formData.department ])

    const onboardUser = () => {           
        document.getElementById('onboard_btn').innerHTML = 'Loading...'
        // if(toggleOnboardType){ // single onboard
        if (formData.department === "others" && formData.others === "") {setErrorData({ ...errorData, others: 'This field is required' }); document.getElementById('onboard_btn').innerHTML = 'Onboard User'}
        else {
            setErrorData({ ...errorData, others: '' })            
            let data = {
                firstname: formData.first_name,
                lastname: formData.last_name,
                emailaddress: formData.email,
                department: formData.department === "others" ? formData.others : formData.department,
                role: joinedRoles,
                activity: "onboard",
                activityName: "initiated user onboarding for "+formData.email,
                isapprove: true,
            }
            // console.log(data)

            if (Object.values(data).every(value => value)){
                data.id = 0
                data.active = false
                document.getElementById('onboard_btn').innerHTML = 'Onboard User'
                var request = {
                    what: "singleOnboardUser",
                    data: data
                };             
                makePostRequest(request)
                    .then((response) => {                     
                        toast.success("Action Successful, user details sent to DGM for approval")
                        handleCloseModal('onboard_user')  // close modal              
                        getAllUsers() 
                    })
                    .catch((error) => toast.error(error.msg));
            }else {
                toast.warning("Please provide information for all required fields")
                document.getElementById('onboard_btn').innerHTML = 'Onboard User'
            }
        }
        // }else{ // bulk onboard           
        //     if(formData.file){
        //         const form = new FormData()
        //         form.append("file", formData.file)

        //         request = {
        //             what: "bulkOnboardUser",
        //             data: form
        //         };             
        //         makePostRequest(request)
        //             .then((response) => {
        //                 document.getElementById('onboard_btn').innerHTML = 'Onboard User'                      
        //                 toast.success("Action Successful, user details sent to DGM for approval")
        //                 handleCloseModal('onboard_user')  // close modal              
        //                 getAllUsers() 
        //             })
        //             .catch((error) => toast.error(error.msg));
        //     }else {
        //         toast.warning("Please provide the required file")
        //         document.getElementById('onboard_btn').innerHTML = 'Onboard User'
        //     }
        // }        
    }

    const manageUser = () => {
        let data = {
            firstname: formData.first_name,
            lastname: formData.last_name,
            emailaddress: formData.email,
            department: formData.department === "others" ? formData.others : formData.department,
            role: formData.role.join(','),
            activity: activity,
            activityName: activityName,
        }
        document.getElementById('manage_btn').innerHTML = 'Loading...' 

        if (Object.values(data).every(value => value)){
            data.active = active
            data.isapprove = false
            data.id = userId
            var request = {
                what: "updateUser",
                // id: userId,
                data: data
            };             
            makePutRequest(request)
                .then((response) => {
                    document.getElementById('manage_btn').innerHTML = 'Submit'                      
                    toast.success(response.msg)
                    handleCloseModal('manage_user')  // close modal              
                    getAllUsers() 
                })
                .catch((error) => toast.error(error.msg));
        }else {
            toast.warning("Please provide information for all required fields")
            document.getElementById('manage_btn').innerHTML = 'Submit'
        }
    }

    const [users, setUsers] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const [role, setRole] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const getAllUsers = () => {
        setIsLoading(true)
        var request = {
            what: "getAllUsers",
            data: { 
                skip: (currentPage - 1) * itemsPerPage,
                limit: itemsPerPage
            },
            params: {}
        };
        if(searchValue) request.params.search = searchValue
        
        makePostRequest(request)
            .then((response) => {
                setIsLoading(false)
                const res = response.data.users
                setTotalItems(response.data.total_records)
                if(role) setUsers(res.filter((user) => user.role?.includes(role)))
                else setUsers(res)
            })
            .catch((error) => toast.error(error.msg));
    }

    const [usersList, setUsersList] = useState([])
    const getADUsers = () => {
        // setIsLoading(true)
        var request = {
            what: "userSearch",
            params: {
                records_per_page: 30000
            }
        };

        makePostRequest(request)
            .then((response) => {
                const res = response.data.all_records
                const formattedRecords = res.map((record) => {
                    return {label: record.FullName, value: record.Email, firstName: record.FirstName, lastName: record.LastName}
                })
                
                setUsersList(formattedRecords)
            })
            .catch((error) => toast.error(error.msg));
    }

    const getUserById = (id) => {
        const user = users.filter(user => user.id === id)[0]
        setFormData({
            first_name: user.firstname,
            last_name: user.lastname,
            email: user.emailaddress,
            role: user.role.includes(',') ? user.role.split(',') : [user.role],
            department: user.department.toLowerCase() === "compliance" ? user.department : "others",
            others: user.department.toLowerCase() !== "compliance" ? user.department : "",
        })
    }

    const sendRequestForApproval = () => {
        if(activity !== "delete") document.getElementById("confirm_da_btn").innerHTML = "Loading..."
        if(activity === "delete") document.getElementById("confirm_delete_btn").innerHTML = "Loading..."
        var request = {
            what: "sendRequestForApproval",
            // id: userId,
            data: {                
                active: active,
                isapprove: isApprove,
                activity: activity,
                activityName: activityName,
                id: userId
            }
        };
        
        makePutRequest(request)
            .then((response) => {
                toast.success("Action Successful. Sent to the DGM for approval")
                setUserId(null)
                setActivity(null)
                setIsApprove(null)
                setActive(null)
                if(activity !== "delete") handleCloseModal("activate_deactivate_user")
                if(activity === "delete") handleCloseModal("delete_user")
                getAllUsers()
            })
            .catch((error) => {
                toast.error(error.msg)
                if(activity !== "delete") document.getElementById("confirm_da_btn").innerHTML = "Yes"
                if(activity === "delete") document.getElementById("confirm_delete_btn").innerHTML = "Yes"
            });
    }

    const [userId, setUserId] = useState(null)
    const [activity, setActivity] = useState(null)
    const [activityName, setActivityName] = useState(null)
    const [isApprove, setIsApprove] = useState(null)
    const [active, setActive] = useState(null)
    const setValues = (id, activity, active, isapprove, activityName) => {
        setUserId(id)
        setActivity(activity)
        setActivityName(activityName)
        setIsApprove(isapprove)
        setActive(active)
    }

    const row = users.map((user, index) => {
        return (
            <tr key={index} title={!user.active 
                && user.activity?.includes("onboard") ? "Onboarded user yet to be approved" : null
             }>
                <td>{ currentPage === 1 ? currentPage + index : (((itemsPerPage*currentPage)-itemsPerPage)+1) + index }</td>
                <td className='capitalize'>{user.firstname} {user.lastname}</td>
                <td>{user.emailaddress}</td>
                <td className='uppercase'>{user.role}</td>
                <td className='capitalize'>{user.department}</td>
                <td><span className={user.active ? 'status success' : 'status danger'}>{user.active ? "Active" : "Inactive"}</span></td>
                
                <td className="actions">
                    <div className="dropdown">
                        <button className="dropbtn">Actions<span className="material-symbols-rounded" style={{fontSize: "20px"}}>keyboard_arrow_down</span></button>
                        <div className="dropdown-content">
                            <button
                            disabled={!user.active && user.activity?.includes("onboard")}
                            onClick={() => {handleOpenModal("manage_user"); 
                            setValues(user.id, "update user", user.active, true, "modified user information for "+user.emailaddress,); getUserById(user.id); setSelectedRoles(user.role.split(",").map(item => item.trim()))}}
                            >Edit</button>

                            {user.active && <button
                            disabled={!user.active && user.activity?.includes("onboard")}
                            onClick={() => {handleOpenModal("activate_deactivate_user"); setValues(user.id, "deactivate", user.active, true, "initiated deactivation for "+ user.emailaddress)}}
                            >Deactivate</button>}

                            {!user.active && <button
                            disabled={!user.active && user.activity?.includes("onboard")}
                            onClick={() => {handleOpenModal("activate_deactivate_user"); setValues(user.id, "activate", user.active, true, "initiated activation for "+ user.emailaddress)}}
                            >Activate</button>}

                            <button
                            disabled={(!user.active 
                                && user.activity?.includes("onboard")
                                ) || USERDETAILS.emailaddress === user.emailaddress}
                            title={USERDETAILS.emailaddress === user.emailaddress ? "You cannot delete/deactivate the current user" : null}
                            onClick={() => {handleOpenModal("delete_user"); setValues(user.id, "delete", user.active, true, "initiated deletion for "+ user.emailaddress)}}
                            >Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        )
    })

    useEffect(() => {
        if(ACCESS_TOKEN) getAllUsers() // eslint-disable-next-line 
    }, [searchValue, role])

    useEffect(() => {
        if(ACCESS_TOKEN) getADUsers() // eslint-disable-next-line 
    }, [formData.email])

    const handleSearch = (event) => {
        const value = event.target.value
        if(!value) setSearchValue(null)
    }

    const handleOfficialEmail = (event, option) => {
        setFormData({
            ...formData,
            'email': option.value,
            'first_name': option.firstName,
            'last_name': option.lastName
        });
    }

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-inner">
                    <div className="main-inner-top">
                        <div className="search-container left-item">
                            <span className="material-symbols-rounded">search</span>
                            <input id="search" placeholder="Search Users" onKeyUp={handleSearch} />
                        </div>
                        <button className="custom-button blue left-item mr-2" onClick={() => 
                            setSearchValue(document.getElementById("search").value)}>Search</button>

                        <select value={role} className='left-item' onChange={(event) => setRole(event.target.value)}>
                            <option key="0" value="">All Roles</option>
                            <option key="1" value="admin">Admin</option>
                            <option key="2" value="dgm">DGM</option>
                            <option key="3" value="investigator">Investigator</option>
                        </select>
                        <button className="custom-button blue right-item"
                            onClick={() => handleOpenModal("onboard_user")}>Onboard User</button>
                    </div>
                    <div className='table-container custom'>
                        <table>
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Name</th>
                                    <th>Official Email</th>
                                    <th>Role</th>
                                    <th>Department</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? null : (
                                        users.length === 0 ? <td className='no-records'>No Records Found</td>
                                        : row
                                    )
                                }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                    <img src={loading} alt="loading" />
                                    <p>Loading data...</p>
                                </div> : null}
                    </div>
                    
                    <div className="pagination-container">
                        <div className="pagination-group">
                            <div>
                                <select defaultValue={itemsPerPage} onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}>
                                    <option value="10">Show 10 Entries</option>
                                    <option value="25">Show 25 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                </select>
                                <p className="page-num">Page {currentPage} of {totalPages}</p>

                            </div>
                            <div className="pagination">
                                <button className="page-item" onClick={handlePrevious} disabled={currentPage === 1} >Previous</button>
                                {
                                    getPageNumbers().map((pageNumber) => (
                                        <button
                                            key={pageNumber}
                                            className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                            onClick={() => setCurrentPage(pageNumber)}
                                        >{ pageNumber }</button>
                                    ))                                    
                                }
                                <button className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    </div> 
                </div>                 
            </div>
            <Modal isOpen={onboardUserModal} style={customStyles} className="modal modal-4" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Onboard User</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("onboard_user")}>close</span>
                </div>
                <div className="modal-body">
                    <>
                        <label><span className="errorX mr-2">*</span>Official Email</label>     
                        <Autocomplete             
                            name="email"                                          
                            filterOptions={filterOptions}                            
                            disableClearable={true}
                            onChange={(e, v) => handleOfficialEmail(e, v)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            options={usersList}                            
                            className='mt-1'                            
                            renderInput={(params) => 
                                <TextField
                                    // onChange={() => {
                                    // formData.email.length < 1 ? setErrorData({ ...errorData, email: 'This field is required' }) : 
                                    // setErrorData({ ...errorData, email: '' })}}
                                    placeholder='Select...' variant='outlined' {...params} />} />
                        <p className="error">{ errorData.email }</p>

                        <div className="d-flex">
                            <div className="mr-2">
                                <label><span className="errorX mr-2">*</span>First Name</label>
                                <input name="first_name" value={formData.first_name} onChange={handleChange} disabled
                                    onKeyUp={() => {
                                        formData.first_name.length < 1 ? setErrorData({ ...errorData, first_name: 'This field is required' }) : 
                                        setErrorData({ ...errorData, first_name: '' })}} />
                                <p className="error">{ errorData.first_name }</p>
                            </div>
                            <div className="ml-2">
                                <label><span className="errorX mr-2">*</span>Last Name</label>
                                <input name="last_name" value={formData.last_name} onChange={handleChange} disabled
                                    onKeyUp={() => {
                                        formData.last_name.length < 1 ? setErrorData({ ...errorData, last_name: 'This field is required' }) : 
                                        setErrorData({ ...errorData, last_name: '' })}} />
                                <p className="error">{ errorData.last_name }</p>
                            </div>
                        </div>                                       

                        <div className="d-flex">
                            <div className="mr-2 w-50">
                                <label><span className="errorX mr-2">*</span>Roles <span className='small-text'>(Multi-Choice)</span></label>  
                                <div className='role-checklist'>                                    
                                    <input
                                        name="role"
                                        type="checkbox"
                                        value="admin"
                                        onChange={handleCheckboxChange}
                                        checked={selectedRoles?.includes("admin")}
                                    /> <label>Admin</label>

                                    <br />
                                    <input
                                        name="role"
                                        type="checkbox"
                                        value="dgm"
                                        onChange={handleCheckboxChange}
                                        checked={selectedRoles?.includes("dgm")}
                                    /> <label>DGM</label>

                                    <br />                                    
                                    <input
                                        name="role"
                                        type="checkbox"
                                        value="investigator"
                                        onChange={handleCheckboxChange}
                                        checked={selectedRoles?.includes("investigator")}
                                    /> <label>Investigator</label>
                                                                        
                                </div>
                                <p className="error">{ errorData.role }</p>
                            </div>
                            <div className="ml-2 w-50">
                                <label><span className="errorX mr-2">*</span>Department</label>
                                <select name='department' value={formData.department} onChange={handleChange}>
                                    <option value="" key="1" disabled>Select Department</option>
                                    <option value="compliance" key="2">Compliance</option>
                                    <option value="others" key="3">Others</option>
                                </select>
                                <p className="error">{ errorData.department }</p>
                            </div>
                        </div>   

                        <label>{ formData.department === "others" && <span className="errorX mr-2">*</span>}Others</label>
                        <input name="others" onChange={handleChange}
                            onKeyUp={() => {
                                formData.others.length < 1 ? setErrorData({ ...errorData, others: 'This field is required' }) : 
                                setErrorData({ ...errorData, others: '' })}} />
                        <p className="error">{ errorData.others }</p>
                    </> 

                    {/* {!toggleOnboardType && 
                    <>
                        <label><span className="errorX mr-2">*</span>File</label>
                        <input name="file" onChange={onFileChange} type="file" accept=".xlsx" />

                        <h5 className="blue-text mt-1" onClick={() => downloadSampleFile()}>Download Sample File (.xlsx)</h5>
                    </>} */}
                </div>
                {/* <p className="or-text">or</p> */}
                <div className="modal-footer bt-1">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("onboard_user")}>Cancel</button>
                    <button id="onboard_btn" type="submit" className="custom-button blue"
                        onClick={() => onboardUser()}>Onboard User</button>
                </div>
            </Modal> 

            <Modal isOpen={manageUserModal} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Edit User</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("manage_user")}>close</span>
                </div>
                <div className="modal-body">
                    <div className="d-flex">
                        <div className="mr-2">
                            <label><span className="errorX mr-2">*</span>First Name</label>
                            <input name="first_name" value={formData.first_name} onChange={handleChange} disabled
                                onKeyUp={() => {
                                    formData.first_name.length < 1 ? setErrorData({ ...errorData, first_name: 'This field is required' }) 
                                    : setErrorData({ ...errorData, first_name: '' })}} />
                            <p className="error">{ errorData.first_name }</p>
                        </div>
                        <div className="ml-2">
                            <label><span className="errorX mr-2">*</span>Last Name</label>
                            <input name="last_name" value={formData.last_name} onChange={handleChange} disabled
                                onKeyUp={() => {
                                    formData.last_name.length < 1 ? setErrorData({ ...errorData, last_name: 'This field is required' }) 
                                    : setErrorData({ ...errorData, last_name: '' })}} />
                            <p className="error">{ errorData.last_name }</p>
                        </div>
                    </div>
                    <label><span className="errorX mr-2">*</span>Official Email</label>
                    <input name="email" value={formData.email} disabled/>
                    <p className="error">{ errorData.email }</p>

                    <div className="d-flex">
                        <div className="mr-2 w-50">
                            <label><span className="errorX mr-2">*</span>Roles</label>
                            <div className='role-checklist'>                                    
                                    <input
                                        name="role"
                                        type="checkbox"
                                        value="admin"
                                        onChange={handleCheckboxChange}
                                        checked={formData.role?.includes("admin")}
                                    /> <label>Admin</label>

                                    <br />
                                    <input
                                        name="role"
                                        type="checkbox"
                                        value="dgm"
                                        onChange={handleCheckboxChange}
                                        checked={formData.role?.includes("dgm")}
                                    /> <label>DGM</label>

                                    <br />                                    
                                    <input
                                        name="role"
                                        type="checkbox"
                                        value="investigator"
                                        onChange={handleCheckboxChange}
                                        checked={formData.role?.includes("investigator")}
                                    /> <label>Investigator</label>
                                                                        
                                </div>
                            <p className="error">{ errorData.role }</p>
                        </div>
                        <div className="ml-2 w-50">
                            <label><span className="errorX mr-2">*</span>Department</label>
                            <select name="department" value={formData.department} onChange={handleChange} >
                                <option disabled key="0">Select Department</option>
                                <option value="compliance" key="1">Compliance</option>
                                <option value="others" key="2">Others</option>
                            </select>
                            <p className="error">{ errorData.department }</p>
                        </div>
                    </div>   

                    <label>{ formData.department === "others" && <span className="errorX mr-2">*</span>}Others</label>
                    <input name="others" onChange={handleChange} value={formData.others}
                        onKeyUp={() => {
                            formData.others.length < 1 ? setErrorData({ ...errorData, others: 'This field is required' }) : 
                            setErrorData({ ...errorData, others: '' })}} />
                    <p className="error">{ errorData.others }</p>
                </div>
                {/* <p className="or-text">or</p> */}
                <div className="modal-footer bt-1">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("manage_user")}>Cancel</button>
                    <button id="manage_btn" type="submit" className="custom-button blue"
                        onClick={() => manageUser()}>Submit</button>
                </div>
            </Modal> 

            <Modal isOpen={confirmActivateDeactivateModal} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Are you sure you want to {activity} this user?</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("activate_deactivate_user")}>close</span>
                </div>
                <div className="modal-footer">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("activate_deactivate_user")}>Cancel</button>
                    <button id="confirm_da_btn" type="submit" className="custom-button blue"
                        onClick={() => sendRequestForApproval()}>Yes</button>
                </div>
            </Modal> 

            <Modal isOpen={confirmDeleteModal} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Are you sure you want to Delete this user?</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("delete_user")}>close</span>
                </div>
                <div className="modal-footer">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("delete_user")}>Cancel</button>
                    <button id="confirm_delete_btn" type="submit" className="custom-button blue"
                        onClick={() => sendRequestForApproval()}>Yes</button>
                </div>
            </Modal>
        </> 
    )
}

export default UserManagement