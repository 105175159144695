import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from "react";
import { ACCESS_TOKEN, makePostRequest } from '../../request';
import loading from "../../assets/images/loading.gif"

const CaseActivityLog = (props) => {
    const {page_title, params, updatePage} = props
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); //limit
    const [totalItems, setTotalItems] = useState(null);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };
    
    const [logs, setLogs] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchValue, setSearchValue] = useState(null)
    const [date, setDate] = useState(null)
    const getAllLogs = () => {
        setIsLoading(true)
        var request = {
            what: "getAllLogs",
            data: { 
                pagination: {
                    skip: (currentPage - 1) * itemsPerPage,
                    limit: itemsPerPage
                },
                desired_statuses: []
            },
            params: { case_id: params.id }
        };
        if(searchValue) request.params.search = searchValue
        if(date) request.params.date = date
        
        makePostRequest(request)
            .then((response) => {
                setIsLoading(false)
                const res = response.data.users_activity_log
                setTotalItems(response.data.total_records)
                setLogs(res)
            })
            .catch((error) => {
                toast.error(error.msg)
            });
    }

    const formatDateTime = (datetime) => {
        const dateString = datetime;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-GB', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit',
            minute:'2-digit',
            hour12: false 
        });
        return formattedDate.replace(/,/g, '');;
    }
    const row = logs.map((log, index) => {
        return (
            <tr key={index}>
                <td>{ currentPage === 1 ? currentPage + index : (((itemsPerPage*currentPage)-itemsPerPage)+1) + index }</td>
                <td>{formatDateTime(log.date)}</td>
                <td className='capitalize no-textwrap'>{log.Name}</td>
                <td className='capitalize'>{log.activityName ? log.activityName : log.activity}</td>
                <td className='uppercase'>{log.role}</td>
            </tr>
        )
    })

    const handleSearch = (event) => {
        const value = event.target.value
        if(!value) setSearchValue(null)
        else setSearchValue(value)
    }

    useEffect(() => {
        if(ACCESS_TOKEN) getAllLogs()
        // eslint-disable-next-line
    }, [currentPage, itemsPerPage]);

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-inner">
                    <div className="detail-top-section">
                        <p onClick={() => updatePage("Case Management")}><span className="material-symbols-rounded">arrow_back</span> Back to Cases</p>
                    </div>
                    <div className="table-header-2">
                        <p>Case ID: <strong>{params.caseno}</strong></p>
                        <div>
                            <input className='right-item' type="date" onChange={(event) => setDate(event.target.value)} />

                            <div className="search-container right-item">
                                <span className="material-symbols-rounded">search</span>
                                <input title='Search by name, role' id="search" placeholder="Search Logs" onKeyUp={handleSearch} />
                            </div>
                            <button className="custom-button blue right-item" onClick={() => 
                                (date || searchValue) ? getAllLogs() : null}>Search</button>
                        </div>
                    </div>
                    <div className="table-container custom">                        
                        <table>
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>DateTime</th>
                                    <th>Name</th>
                                    <th>Activity</th>
                                    <th>Role</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? null : (
                                        logs.length === 0 ? <td className='no-records'>No Records Found</td>
                                        : row
                                    )
                                }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                    <img src={loading} alt="loading" />
                                    <p>Loading data...</p>
                                </div> : null}
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-group">
                            <div>
                                <select onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}>
                                    <option value="10">Show 10 Entries</option>
                                    <option value="25">Show 25 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                </select>
                                <p className="page-num">Page {currentPage} of {totalPages}</p>

                            </div>
                            <div className="pagination">
                                <button className="page-item" onClick={handlePrevious} disabled={currentPage === 1} >Previous</button>
                                {
                                    getPageNumbers()
                                        .slice(0, Math.min(3, getPageNumbers().length)) // Limit the range of the array to the first 2 elements
                                        .map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                                onClick={() => setCurrentPage(pageNumber)}
                                            >{pageNumber}</button>
                                        ))                                        
                                }
                                {getPageNumbers().length > 3 && <button className='page-item'>...</button>}

                                {getPageNumbers().length > 3 && 
                                <button
                                    className={`page-item ${currentPage === getPageNumbers().length ? 'active' : ''}`}    
                                >
                                    {getPageNumbers().length}
                                </button>}
                                <button className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    </div>                    
                </div>                 
            </div>
            <ToastContainer />
        </> 
    )
}

export default CaseActivityLog