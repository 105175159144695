import { toast } from 'react-toastify';
import { useEffect, useState } from "react";
import Modal from 'react-modal';
import { ACCESS_TOKEN, makePostRequest, makePutRequest, makeDeleteRequest } from '../../request';
import loading from "../../assets/images/loading.gif"

const Classifications = (props) => {
    const {page_title} = props

    // const [currentPage, setCurrentPage] = useState(1);
    // const [itemsPerPage, setItemsPerPage] = useState(10); //limit
    // const [totalItems, setTotalItems] = useState(null);
    // const totalPages = Math.ceil(totalItems / itemsPerPage);

    // const handlePrevious = () => {
    //     if (currentPage > 1) {
    //         setCurrentPage(currentPage - 1);
    //     }
    // };

    // const handleNext = () => {
    //     if (currentPage < totalPages) {
    //         setCurrentPage(currentPage + 1);            
    //     }
    // };

    // const getPageNumbers = () => {
    //     const pageNumbers = [];
    //     for (let i = 1; i <= totalPages; i++) {
    //         pageNumbers.push(i);
    //     }
    //     return pageNumbers;
    // };

    // useEffect(() => {
    //     // You can use the 'skip' value for your API calls or data fetching here
    //     getAllClassifications() // eslint-disable-next-line
    // }, [currentPage, itemsPerPage]);

    const [createClassificationModal, setCreateClassificationModal] = useState(false)
    const [editClassificationModal, setEditClassificationModal] = useState(false)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)

    const [formData, setFormData] = useState({
        classification: null
    })

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    }; 

    const handleOpenModal = (modal) => {
        switch(modal){
            case "create_classification":
                setCreateClassificationModal(true)
                setFormData({
                    classification: null
                })
                break

            case "confirm_delete":
                setConfirmDeleteModal(true)
                break

            case "edit_classification":
                setEditClassificationModal(true)
                setFormData({
                    classification: null
                })
                break

            default:
                break
        }
    }
    const handleCloseModal = (modal) => {
        switch(modal){
            case "create_classification":
                setCreateClassificationModal(false)
                break

            case "confirm_delete":
                setConfirmDeleteModal(false)
                setClassificationId(null)
                break

            case "edit_classification":
                setEditClassificationModal(false)
                setClassificationId(null)
                break

            default:
                break
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target //get data form each input on change
        setFormData(values => ({...formData, [name]: value})) //set retrieved values to "formData" object  
    }

    const CreateClassification = () => {           
        document.getElementById('submit_btn').innerHTML = 'Loading...'
        if (formData.classification === null) {toast.error("Kindly provide the required field"); document.getElementById('submit_btn').innerHTML = 'Submit'}
        else {           
            let data = {
                classification: formData.classification
            }
            document.getElementById('submit_btn').innerHTML = 'Submit'
            var request = {
                what: "createClassification",
                data: data
            };             
            makePostRequest(request)
                .then((response) => {                     
                    toast.success("Action Successful")
                    handleCloseModal('create_classification')  // close modal              
                    getAllClassifications() 
                })
                .catch((error) => toast.error(error.msg));
        }    
    }

    const [classificationId, setClassificationId] = useState(null)
    const editClassification = () => {
        let data = {
            Classifications: formData.classification,
            id: classificationId
        }
        document.getElementById('edit_btn').innerHTML = 'Loading...' 

        if (Object.values(data).every(value => value)){
            var request = {
                what: "updateClassification",
                data: data
            };             
            makePutRequest(request)
                .then((response) => {
                    document.getElementById('edit_btn').innerHTML = 'Submit'                      
                    toast.success(response.msg)
                    handleCloseModal('edit_classification')  // close modal              
                    getAllClassifications() 
                })
                .catch((error) => toast.error(error.msg));
        }else {
            toast.warning("Kindly provide the required field")
            document.getElementById('edit_btn').innerHTML = 'Submit'
        }
    }

    const deleteClassification = () => {        
        var request = {
            what: "deleteClassification",
            id: classificationId,
        };
        document.getElementById("confirm_delete_btn").innerHTML = "Loading..."
        makeDeleteRequest(request)
            .then((response) => {
                toast.success(response.msg)
                handleCloseModal("confirm_delete")
                getAllClassifications()
            })
            .catch((error) => {
                toast.error(error.msg)
                document.getElementById("confirm_delete_btn").innerHTML = "Yes"
            });
    }

    const [classifications, setClassifications] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const getAllClassifications = () => {
        setIsLoading(true)
        var request = {
            what: "getAllClassifications",
            params: {}
        };
        if(searchValue) request.params.search = searchValue
        
        makePostRequest(request)
            .then((response) => {
                setIsLoading(false)
                const res = response.data.classification
                setClassifications(res)
            })
            .catch((error) => toast.error(error.msg));
    }

    const row = classifications.map((row, index) => {
        return (
            <tr key={index}>
                <td>{index+1}</td>
                <td className='capitalize'>{row.Classifications}</td>
                
                <td className="actions">
                    <div class="dropdown">
                        <button class="dropbtn">Actions<span className="material-symbols-rounded" style={{fontSize: "20px"}}>keyboard_arrow_down</span></button>
                        <div class="dropdown-content">
                            <button
                            onClick={() => {handleOpenModal("edit_classification"); 
                            setFormData(values => ({...formData, classification: classifications.filter(item => item.Id === row.Id)[0].Classifications})); 
                            setClassificationId(row.Id)}}
                            >Edit</button>

                            <button onClick={() => {handleOpenModal("confirm_delete"); setClassificationId(row.Id)}}
                            >Delete</button>
                        </div>
                    </div>
                </td>
            </tr>
        )
    })

    useEffect(() => {
        if(ACCESS_TOKEN) getAllClassifications() // eslint-disable-next-line 
    }, [searchValue])

    const handleSearch = (event) => {
        const value = event.target.value
        if(!value) setSearchValue(null)
    }

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-inner">
                    <div className="main-inner-top">
                        <div className="search-container left-item">
                            <span className="material-symbols-rounded">search</span>
                            <input id="search" placeholder="Search Classifications" onKeyUp={handleSearch} />
                        </div>
                        <button className="custom-button blue left-item mr-2" onClick={() => 
                            setSearchValue(document.getElementById("search").value)}>Search</button>
                        <button className="custom-button blue right-item"
                            onClick={() => handleOpenModal("create_classification")}>Create Classification</button>
                    </div>
                    <div className='table-container custom'>
                        <table>
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Classification</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? null : (
                                        classifications.length === 0 ? <td className='no-records'>No Records Found</td>
                                        : row
                                    )
                                }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                    <img src={loading} alt="loading" />
                                    <p>Loading data...</p>
                                </div> : null}
                    </div>
                    
                    {/* <div className="pagination-container">
                        <div className="pagination-group">
                            <div>
                                <select onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}>
                                    <option value="10">Show 10 Entries</option>
                                    <option value="25">Show 25 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                </select>
                                <p className="page-num">Page {currentPage} of {totalPages}</p>

                            </div>
                            <div className="pagination">
                                <button className="page-item" onClick={handlePrevious} disabled={currentPage === 1} >Previous</button>
                                {
                                    getPageNumbers().map((pageNumber) => (
                                        <button
                                            key={pageNumber}
                                            className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                            onClick={() => setCurrentPage(pageNumber)}
                                        >{ pageNumber }</button>
                                    ))                                    
                                }
                                <button className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    </div>  */}
                </div>                 
            </div>
            <Modal isOpen={createClassificationModal} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Create Classification</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("create_classification")}>close</span>
                </div>
                <div className="modal-body">                    
                    <label><span className="errorX mr-2">*</span>Classification</label>
                    <input name="classification" value={formData.classification} onChange={handleChange}  />                        
                </div>       
                <div className="modal-footer bt-1">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("create_classification")}>Cancel</button>
                    <button id="submit_btn" type="submit" className="custom-button blue"
                        onClick={() => CreateClassification()}>Submit</button>
                </div>
            </Modal> 

            <Modal isOpen={editClassificationModal} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Edit Classification</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("edit_classification")}>close</span>
                </div>
                <div className="modal-body">
                    <label><span className="errorX mr-2">*</span>Classification</label>
                    <input name="classification" value={formData.classification} onChange={handleChange} />
                </div>
                <div className="modal-footer bt-1">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("edit_classification")}>Cancel</button>
                    <button id="edit_btn" type="submit" className="custom-button blue"
                        onClick={() => editClassification()}>Submit</button>
                </div>
            </Modal> 

            <Modal isOpen={confirmDeleteModal} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Are you sure you want to Delete this classification?</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("confirm_delete")}>close</span>
                </div>
                <div className="modal-footer">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("confirm_delete")}>Cancel</button>
                    <button id="confirm_delete_btn" type="submit" className="custom-button blue"
                        onClick={() => deleteClassification()}>Yes</button>
                </div>
            </Modal>
        </> 
    )
}

export default Classifications