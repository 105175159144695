import axios from 'axios'
const baseUrl = process.env.REACT_APP_SERVER_URL
const redirectURI = process.env.REACT_APP_FRONTEND_URL
var ACCESS_TOKEN;
var USERDETAILS;

const loginTepngUser = () => {
    return new Promise((resolve, reject) =>  { 
        axios({      
                method: 'POST',            
                url: process.env.REACT_APP_SERVER_URL + '/azure-login',            
                data: {},            
                withCredentials: true,            
                headers: { "Content-Type": "application/json" },            
                responseType: 'json'
            
            }).then(res => {  
                
                ACCESS_TOKEN = res.data.access_token
                USERDETAILS = res.data.userdetails
                
                resolve({
                    msg: "Login Successful",
                    role: USERDETAILS.role,
                    status: true
                })            
            
            }).catch(error => {
                
                let queryParams = new URLSearchParams(window.location.search);

                if (queryParams.get("azureauthredirect") !== '1' && error.response.status === 401) {
                    let url = new URL(process.env.REACT_APP_FRONTEND_URL)// return to login page
                    url.searchParams.append('azureauthredirect', '1')
                    window.location.replace(process.env.REACT_APP_SERVER_URL + '/.auth/login/aad?post_login_redirect_uri=' + window.encodeURIComponent(url.href));                    
                }else {                   
                    window.location.replace(process.env.REACT_APP_FRONTEND_URL+"/unauthorized")
                };
        })
    })
}

const logoutTepngUser = () => {
    return new Promise((resolve, reject) =>  { 
        axios({      
                method: 'POST',            
                url: process.env.REACT_APP_SERVER_URL + '/azure-logout',            
                data: {},            
                withCredentials: true,            
                headers: { "Content-Type": "application/json" },            
                responseType: 'json'
            
            }).then(res => {  

                resolve({
                    msg: "Logout Successful",
                    status: true
                })          
            
            }).catch(error => {
                reject(error)
            })
    })
}

const authenticateLocally = () => {

    var request = {
        what: "authUser",
        // params: { email: 'chidozie.ozigbo@external.totalenergies.com' }
        params: { email: 'ifeoma.nnamah@external.totalenergies.com' }
        // params: { email: 'kenneth.amba@totalenergies.com' }
    };
    return new Promise((resolve, reject) => {
        axios({      
            method: 'POST',            
            url: process.env.REACT_APP_SERVER_URL + '/test-login?email='+request.params.email,            
            data: {},            
            withCredentials: true        
        }).then((res) => {
            ACCESS_TOKEN = res.data.access_token;
            USERDETAILS = res.data.userdetails;
      
            resolve({
              msg: "Login Successful",
              role: USERDETAILS.role,
              status: true,
            });
          }).catch((error) => {
            if(error.response.status === 401) {
                const currentUrl = window.location.href
                window.location.replace(currentUrl+"unauthorized")
            }            
          })
      });
      
}

const makeGetRequest = (request) =>  {
    const request_urls =  {
        // 'getAllUsers':"/listusers", 
    }

    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) =>  {return key + '=' + obj[key] }).join('&')
        var request_url = baseUrl + request_urls[request.what] + "?" + params
    }else {
        request_url = baseUrl + request_urls[request.what]
    }
    
    return new Promise((resolve, reject) =>  {
        axios.get(request_url,  {
            headers: {
                'Authorization':'Bearer ' + ACCESS_TOKEN, 
                'Content-Type':'application/json'
            }
        })
        .then((response) =>  {
            var res =  {
                msg:"Action Successful", 
                type:request.what, 
                status:true, 
                data:response.data
            }
            resolve(res)
        })
        .catch((err) =>  {
            // if (err.response)reject(err.response.data.msg)
            // else reject("Network Error. Reload the page or check your internet connection")
            if (err.response) {
                if(err.response.data) reject(err.response.data.detail)
                if(err.response.status === 401) window.location.replace(redirectURI)
            }else reject(err.message)
        })
    })
}

const makePostRequest = (request) =>  {

    const request_urls =  {
        'authUser':"/test-login", 
        'getAllUsers':"/listusers", 
        'getAllClassifications':"/listclassification", 
        'getAllCases':"/listcases", 
        'getAllInvestigators':"/listinvestigator", 
        'assignCase':"/assign_case", 
        'bulkOnboardUser':"/upload-users", 
        'singleOnboardUser':"/users", 
        'getAllLogs':"/useractivitylog", 
        'getInvestigatorCases': "/listinvestigatorcase",
        'uploadReport': "/uploadreport",
        "downloadReport": "/downloadreport",
        "dashboardInvestigator": "/dashboard/investigator",
        "dashboardDgm": "/dashboard/dgm",
        "exportCases": "/exportcases",
        "sendNotification": "/senderemail/"+request.id+"/caseclose",
        "createClassification": "/classification",
        "userSearch": "/usersearch"
    }

    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) =>  {return key + '=' + obj[key] }).join('&')
        var request_url = baseUrl + request_urls[request.what] + "?" + params
    }else {
        request_url = baseUrl + request_urls[request.what]
    }

    var data = (request.data !== undefined)?request.data: {}
    var config; 
    // if (request_url !== baseUrl + '') {// do not set if it is pointing to 'authenticate'
        
    // }
    config =  {
        headers: {
            'Authorization':'Bearer ' + ACCESS_TOKEN
        },
        withCredentials: true, 
    }
    if(ACCESS_TOKEN === null) refreshToken()
    else {
        return new Promise((resolve, reject) =>  {
            axios.post(request_url, data, config)
                .then((response) =>  {
                    var res; 
                    res =  {
                        msg:"Action Successful", 
                        type:request.what, 
                        status:true, 
                        data:response.data
                    }
                    resolve(res)
                })
                .catch((err) =>  {
                    if (err.response) {
                        if(err.response.data) {
                            reject({
                                msg: err.response.data.detail,
                                statuscode: err.response.status,
                            })
                            if(err.response.status === 401) window.location.replace(redirectURI)
                        }
                    }else reject({msg: err.message})
                })
        })
    }
}

// const refreshToken = () => {
//     var request = {
//         what: "authUser",
//         params: { email: 'ifeoma.nnamah@external.totalenergies.com' }
//     };
//     return new Promise((resolve, reject) =>  { 
//     makePostRequest(request)
//         .then((res) => {
//             ACCESS_TOKEN = res.data.refresh_token
//             USERDETAILS = res.data.userdetails

//             resolve({
//                 msg: "Login Successful",
//                 role: USERDETAILS.role,
//                 status: true
//             }) 
//         })
//     })
// }

const refreshToken = () => {
    return new Promise((resolve, reject) =>  { 
        axios({      
            method: 'POST',            
            url: process.env.REACT_APP_SERVER_URL + '/azure-login',            
            data: {},            
            withCredentials: true,            
            headers: { "Content-Type": "application/json" },            
            responseType: 'json'
        
        }).then(res => {          
            ACCESS_TOKEN = res.data.access_token
            USERDETAILS = res.data.userdetails
            resolve({
                msg: "Login Successful",
                role: USERDETAILS.role,
                status: true
            })  
        })
    })
}

if(ACCESS_TOKEN === null || ACCESS_TOKEN === undefined) refreshToken()

const makePatchRequest = (request) =>  {

    const request_urls =  {
        
    }

    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) =>  {return key + '=' + obj[key] }).join('&')
        var request_url = baseUrl + request_urls[request.what] + "?" + params
    }else {
        request_url = baseUrl + request_urls[request.what]
    }

    var data = (request.data !== undefined)?request.data: {}
    var config =  {
        headers: {
            'Authorization':'Bearer ' + ACCESS_TOKEN
        },
        withCredentials: true, 
    }

    //console.log('%cSending patch request to: ' + request_url, 'color:#00ff00;font-size:14px;background:#000;')
    return new Promise((resolve, reject) =>  {
        axios.patch(request_url, data, config)
            .then((response) =>  {
                var res; 
                res =  {
                    msg:"Action Successful", 
                    type:request.what, 
                    status:true, 
                    data:response.data
                }
                resolve(res)
            })
            .catch((err) =>  {
                if (err.response) {
                    if(err.response.data) reject(err.response.data.detail)
                    if(err.response.status === 401) window.location.replace(redirectURI)
                }else reject(err.message)
            })
    })
}

const makePutRequest = (request) =>  {

    const request_urls =  {
        'sendRequestForApproval':'/users', 
        'updateUser':'/updateusers', 
        'updateCaseStatus':'/cases/' + request.id + '/update_status', 
        'respondToReport':'/report/' + request.id + '/update_report',
        "updateClassification": '/classification'
    }

    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) =>  {return key + '=' + obj[key] }).join('&')
        var request_url = baseUrl + request_urls[request.what] + "?" + params
    }else {
        request_url = baseUrl + request_urls[request.what]
    }

    var data = (request.data !== undefined)?request.data: {}
    var config =  {
        headers: {
            'Authorization':'Bearer ' + ACCESS_TOKEN
        },
        withCredentials: true, 
    }

    return new Promise((resolve, reject) =>  {
        axios.put(request_url, data, config)
            .then((response) =>  {
                var res; 
                res =  {
                    msg:"Action Successful", 
                    type:request.what, 
                    status:true, 
                    data:response.data
                }
                resolve(res)
            })
            .catch((err) =>  {
                if (err.response) {
                    if(err.response.data) {
                        reject({
                            msg: err.response.data.detail,
                            statuscode: err.response.status
                        })
                        if(err.response.status === 401) window.location.replace(redirectURI)
                    }
                }else reject({msg: err.message})
            })
    })
}

const makeDeleteRequest = (request) =>  {

    const request_urls =  {
        "deleteUser":'/users/' + request.id + '/delete',
        "deleteClassification": '/classification/' + request.id + '/delete'
    }

    if (request.params !== undefined) {
        var obj = request.params
        var params = Object.keys(obj).map((key) =>  {return key + '=' + obj[key] }).join('&')
        var request_url = baseUrl + request_urls[request.what] + "?" + params
    }else {
        request_url = baseUrl + request_urls[request.what]
    }

    var data = (request.data !== undefined)?request.data: {}
    var config =  {
        headers: {
            'Authorization':'Bearer ' + ACCESS_TOKEN
        },
        withCredentials: true, 
    }

    return new Promise((resolve, reject) =>  {
        axios.delete(request_url, config, data)
            .then((response) =>  {
                var res; 
                res =  {
                    msg:"Action Successful", 
                    type:request.what, 
                    status:true, 
                    data:response.data
                }
                resolve(res)
            })
            .catch((err) =>  {
                if (err.response) {
                    if(err.response.data) {
                        reject({
                            msg: err.response.data.detail,
                            statuscode: err.response.status
                        })
                        if(err.response.status === 401) window.location.replace(redirectURI)
                    }
                }else reject({msg: err.message})
            })
    })
}

export {makeGetRequest, makePostRequest, makePutRequest, makePatchRequest, 
    makeDeleteRequest, baseUrl, redirectURI, ACCESS_TOKEN, USERDETAILS, 
    loginTepngUser, logoutTepngUser, authenticateLocally, refreshToken }