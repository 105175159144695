// import { useState } from 'react'
import { useNavigate, Outlet } from "react-router-dom";
import logo from '../src/assets/images/totalenergies.jpg'
import { ToastContainer, toast } from 'react-toastify';
import loading from "../src/assets/images/loading.gif"
import { loginTepngUser } from "../src/request"
import { authenticateLocally } from "../src/request"

const Authentication = () => {
    const navigate = useNavigate();       
    const isDev = false // Toggle between TestLogin and ADLogin

    if(isDev){
        authenticateLocally().then((res) => {    
            if(res.status) {
                toast.success(res.msg) 
                if(res.role.includes(',')) navigate("/validaterole")
                else navigate("/"+res.role)
            }else toast.error(res)
        })
    }else{ 
        loginTepngUser().then(res => {
            if(res.status) {
                toast.success(res.msg) 
                localStorage.clear()
                if(res.role.includes(',')) navigate("/validaterole")
                else navigate("/"+res.role.trim())
            } 
        })   
    }
    
    return (
        <>
            <div className="authentication-container">
                <div className="authentication-modal">
                    <img width="130px" src={logo} alt="totalenergies" />
                    <h3>Ethics & Integrity Alert <br/> Management System</h3>
                    <img src={loading} alt='' width="80px" />
                    <p>Checking Microsoft AD credentials <br /> to access the application</p>
                </div>
            </div>

            <ToastContainer />
            <Outlet />
        </>
    )
}

export default Authentication