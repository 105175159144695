import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect } from "react";
import Modal from 'react-modal';
import { makePostRequest, makePutRequest, makeDeleteRequest, ACCESS_TOKEN, USERDETAILS } from '../../request';
import loading from "../../assets/images/loading.gif"

const UserManagement = (props) => {
    const {page_title} = props
    const [confirmModal, setConfirmModal] = useState(false)
    const [confirmationType, setConfirmationType] = useState(null)

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100); //limit
    const [totalItems, setTotalItems] = useState(null);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);            
        }
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    useEffect(() => {
        // You can use the 'skip' value for your API calls or data fetching here
        getAllUsers() // eslint-disable-next-line
    }, [currentPage, itemsPerPage]);
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    }; 

    const handleOpenModal = (modal) => {
        switch(modal){
            case "confirm":
                setConfirmModal(true)
                break

            default:
                break
        }
    }
    const handleCloseModal = (modal) => {
        switch(modal){
            case "confirm":
                setConfirmModal(false)
                break
            
            default:
                break
        }
    }

    const [users, setUsers] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const getAllUsers = () => {
        setIsLoading(true)
        var request = {
            what: "getAllUsers",
            data: { 
                skip: (currentPage - 1) * itemsPerPage,
                limit: itemsPerPage
            },
            params: {}
        };
        if(searchValue) request.params.search = searchValue
        
        makePostRequest(request)
            .then((response) => {
                setIsLoading(false)
                const res = response.data.users.filter(user => user.isapprove || user.isapprove === null)
                setTotalItems(response.data.total_records) 
                setUsers(res)
            })
            .catch((error) => {
                toast.error(error.msg)
            });
    }

    const row = users.map((user, index) => {
        return (
            <tr key={index}>
                <td>{ currentPage === 1 ? currentPage + index : (((itemsPerPage*currentPage)-itemsPerPage)+1) + index }</td>
                <td className='capitalize'>{user.firstname} {user.lastname}</td>
                <td>{user.emailaddress}</td>
                <td className='uppercase'>{user.role}</td>
                <td className='capitalize'>{user.department}</td>
                <td className='capitalize'>{user.activity}</td>
                <td className="actions">
                    <button className="custom-button green" 
                    disabled={USERDETAILS.emailaddress === user.emailaddress}
                    title={USERDETAILS.emailaddress === user.emailaddress ? "You cannot delete/deactivate the current user" : null}
                    onClick={() => {handleOpenModal("confirm");                     
                    setConfirmationType("approve"); 
                    user.activity !== "delete" ? setValues(user.id, user.activity, user.activity !== "deactivate" ? true : false, false, "approved "+user.activity+" for "+user.emailaddress)
                    : setUserId(user.id);setActivity(user.activity) }}>Approve
                    </button>
                    
                    <button className="custom-button red-outline" onClick={() => {handleOpenModal("confirm"); 
                    setConfirmationType("reject"); setValues(user.id, user.activity, user.active, false, "rejected "+user.activity+" for "+user.emailaddress)}}>Reject</button>
                </td>
            </tr>
        )
    })
    
    const [userId, setUserId] = useState(null)
    const [activity, setActivity] = useState(null)
    const [activityName, setActivityName] = useState(null)
    const [isApprove, setIsApprove] = useState(null)
    const [active, setActive] = useState(null)
    const setValues = (id, activity, active, isapprove, activityName) => {
        setUserId(id)
        setActivity(activity)
        setActivityName(activityName)
        setIsApprove(isapprove)
        setActive(active)
    }

    const sendRequestForApproval = () => {
        document.getElementById("confirm_btn").innerHTML = "Loading..."
        var request = {
            what: "sendRequestForApproval",
            // id: userId,
            data: {                
                active: active,
                isapprove: isApprove,
                activity: confirmationType === "approve" ? activity+ " approved" : activity+" rejected",
                activityName: activityName,
                id: userId
            }
        };
        
        makePutRequest(request)
            .then((response) => {
                toast.success(response.msg)
                setUserId(null)
                setActivity(null)
                setIsApprove(null)
                setActive(null)
                handleCloseModal("confirm")
                getAllUsers()
            })
            .catch((error) => {
                toast.error(error.msg)
                document.getElementById("confirm_btn").innerHTML = "Yes"
            });
    }

    const deleteUser = () => {        
        var request = {
            what: "deleteUser",
            id: userId,
        };
        document.getElementById("confirm_btn").innerHTML = "Loading..."
        makeDeleteRequest(request)
            .then((response) => {
                toast.success(response.msg)
                setUserId(null)
                setActivity(null)
                handleCloseModal("confirm")
                getAllUsers()
            })
            .catch((error) => {
                toast.error(error.msg)
                document.getElementById("confirm_btn").innerHTML = "Yes"
            });
    }

    useEffect(() => {
        if(ACCESS_TOKEN) getAllUsers() // eslint-disable-next-line 
    }, [searchValue])
    
    const handleSearch = (event) => {
        const value = event.target.value
        if(!value) setSearchValue(null)
    }

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-inner">
                    <div className="main-inner-top">
                        <div className="search-container left-item">
                            <span className="material-symbols-rounded">search</span>
                            <input id="search" placeholder="Search Users" onKeyUp={handleSearch} />
                        </div>
                        <button className="custom-button blue left-item mr-2" onClick={() => 
                            setSearchValue(document.getElementById("search").value)}>Search</button>
                        <div className='right-item'></div>
                    </div>
                    <div className='table-container custom'>
                        <table>
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Name</th>
                                    <th>Official Email</th>
                                    <th>Role</th>
                                    <th>Department</th>
                                    <th>Activity</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? null : (
                                        users.length === 0 ? <td className='no-records'>No Records Found</td>
                                        : row
                                    )
                                }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                <img src={loading} alt="loading" />
                                <p>Loading data...</p>
                            </div> : null}
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-group">
                            <div>
                                <select value={itemsPerPage} onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}>
                                    <option value="10">Show 10 Entries</option>
                                    <option value="25">Show 25 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                </select>
                                <p className="page-num">Page {currentPage} of {totalPages}</p>

                            </div>
                            <div className="pagination">
                                <button className="page-item" onClick={handlePrevious} disabled={currentPage === 1} >Previous</button>
                                {
                                    getPageNumbers().map((pageNumber) => (
                                        <button
                                            key={pageNumber}
                                            className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                            onClick={() => setCurrentPage(pageNumber)}
                                        >{ pageNumber }</button>
                                    ))                                    
                                }
                                <button className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>                 
            </div>

            <Modal isOpen={confirmModal} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Are you sure you want to {confirmationType} the action to {activity} this user?</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("confirm")}>close</span>
                </div>
                <div className="modal-footer">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("confirm")}>Cancel</button>
                    <button id="confirm_btn" type="submit" className="custom-button blue"
                        onClick={() => (activity === "delete" && confirmationType==="approve") ? deleteUser() : sendRequestForApproval()}>Yes</button>
                </div>
            </Modal>
            <ToastContainer />
        </> 
    )
}

export default UserManagement