import image from "./assets/images/blocked.png"
import { useNavigate } from 'react-router-dom';

const DeniedAccess = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="blocked-container">
                <div>
                    <img src={image} alt="accessdenied" width="50px" />
                    <h3>Access Denied</h3>
                    <p>You do not have sufficient access to view this page.</p>

                    <button className="custom-button blue" onClick={() => navigate('/')}> Go Back</button>
                </div>
            </div>        
        </>
    )
}

export default DeniedAccess