import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "../Header"
import { useState } from "react";
import UserManagement from "./UserManagement";
import Classification from "./Classifications";
import { refreshToken } from "../../request";

const sidebarItems = [
  {
    title: "User Management",
    icon: "group",
  },
  {
    title: "Classification",
    icon: "settings_applications",
  },
];

const Sidebar = (props) => {
    const { updatePage, page } = props;
    
    return (
      <div className="sidenav">
        <ul>
          {sidebarItems.map((item, index) => (
            <li
              key={index}
              className={page === item.title ? "nav-item active" : "nav-item"}
              onClick={() => updatePage(item.title)}
            >
              <span className="material-symbols-rounded">{item.icon}</span>
              {item.title}
            </li>
          ))}
        </ul>
      </div>
    );
} 

const Admin = () => {
    const navigate = useNavigate();
    const [isAllowed, setIsAllowed] = useState(false)
    const [page, setPage] = useState(
      (localStorage.getItem("page") === null || !localStorage.getItem("page").includes(sidebarItems.map((item) => item.title))) ? "User Management" 
      : localStorage.getItem("page"))

    const updatePage = (page) => { if(page !== "Approvals"){
      localStorage.setItem("page", page)
      setPage(page)
      
    }} 

    refreshToken().then((res) => {
      if(res.status){
        if(!res.role.includes("admin")) {navigate('/error'); localStorage.clear()}
        else setIsAllowed(true)
      }
    })
    
    return (
        <>
            {isAllowed && 
              <>
                <Header />
                <Sidebar page={page} updatePage={updatePage} />
                <div className="container">
                    {page=== "User Management" && <UserManagement page_title={page} />}
                    {page=== "Classification" && <Classification page_title={page} />}
                </div>              
                <Outlet />
                <ToastContainer/>
              </>
            }
        </>
    )
}

export default Admin