import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect } from "react";
import Modal from 'react-modal';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ACCESS_TOKEN, makePostRequest } from '../../request';
import loading from "../../assets/images/loading.gif"
// import * as FileSaver from "file-saver";
import {ExcelFile, ExcelSheet, ExcelColumn} from 'react-xlsx-wrapper';
// import * as XLSX from "xlsx";

const CaseManagement = (props) => {
    const {page_title, updatePage, setParams} = props
    const [isOpenCaseProgess, setIsOpenCaseProgress] = useState(false);
    const [isOpenExport, setIsOpenExport] = useState(false);
    // const [activeStep, setActiveStep] = useState(4);
    const [ caseStatusIndex, setCaseStatusIndex ] = useState(null)
    const [ isHoldsMerit, setIsHoldsMerit ] = useState(null)
    const [ staffinvolved , setStaffInvolved ] = useState(null)
    
    const status = [
        {
          label: "Case Unassigned",
          completed: caseStatusIndex === 0,
        },
        {
          label: "Case Assigned",
          completed: caseStatusIndex >= 1,
        },
        {
          label: "WorkPlan Report Ongoing",
          completed: caseStatusIndex >= 2,
        },
        {
          label: "Workplan Report Under Review",
          completed: caseStatusIndex >= 3,
        },
        {
            label: "Awaiting Preliminary Investigation",
            completed: caseStatusIndex >= 4,
        },
        {
            label: "Preliminary Investigation Ongoing",
            completed: caseStatusIndex >= 5,
        },
        {
            label: "Preliminary Investigation Report Under Review",
            completed: caseStatusIndex >= 6,
        },
        {
            label: "Awaiting Full Investigation",
            completed: caseStatusIndex >= 7  && isHoldsMerit //valid if case holdsmerit,
        },
        {
            label: "Full Investigation Ongoing",
            completed: caseStatusIndex >= 8  && isHoldsMerit //valid if case holdsmerit,
        },
        {
            label: "Full Investigation Report Under Review",
            completed: caseStatusIndex >= 9  && isHoldsMerit //valid if case holdsmerit,
        },
        {
            label: "IC Review",
            completed: caseStatusIndex >= 10
        },
        {
            label: "IC Review Completed",
            completed: caseStatusIndex >= 11
        },
        {
            label: "Case Transferred to C & P", // Contract Staff
            completed: caseStatusIndex >= 12 && staffinvolved === 'contract'
        },
        {
            label: "Case Transferred to PTM & DC", // TUCN Staff 
            completed: caseStatusIndex >= 13 && staffinvolved === 'tucn'
        },
        {
          label: "Case Closed",
          completed: caseStatusIndex >= 14
        }
    ]

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); //limit
    const [totalItems, setTotalItems] = useState(null);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    }; 

    const handleOpenModal = (modal) => {
        switch(modal){
            case "progress":
                setIsOpenCaseProgress(true)
                break

            case "export":
                setIsOpenExport(true)
                break

            default:
                break
        }
    }

    const handleCloseModal = (modal) => {
        switch(modal){
            case "progress":
                setIsOpenCaseProgress(false)
                break

            case "export":
                setIsOpenExport(false)

                setExportFilterValue(null)
                setFromDate(null)
                setToDate(null)
                break

            default:
                break
        }
    }

    const [cases, setCases] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchValue, setSearchValue] = useState(null)
    const [filterValue, setFilterValue] = useState(null)
    
    const applyFilter = (value) => {
        switch(value){
            case "unassigned":
                return ["Case Unassigned"]

            case "closed":
                return ["Case Closed"]

            case "ongoing":
                return [
                    "Case Assigned", 
                    "WorkPlan Report Ongoing", 
                    "Workplan Report Under Review",
                    "Awaiting Preliminary Investigation",
                    "Preliminary Investigation Ongoing",
                    "Preliminary Investigation Report Under Review",
                    "Awaiting Full Investigation",
                    "Full Investigation Ongoing",
                    "Full Investigation Report Under Review",
                    "IC Review",
                    "IC Review Completed",
                    "Case Transferred to C & P",
                    "Case Transferred to PTM & DC",
                ]

            default:
                return []
        }
    }
    const getAllCases = () => {
        setIsLoading(true)
        var request = {
            what: "getAllCases",
            data: { 
                pagination: {
                    skip: (currentPage - 1) * itemsPerPage,
                    limit: itemsPerPage
                },
                desired_statuses: applyFilter(filterValue)
            },
            params: {}
        };
        if(searchValue) request.params.search = searchValue
        
        makePostRequest(request)
            .then((response) => {
                setIsLoading(false)
                const res = response.data.cases
                setTotalItems(response.data.total_records)
                setCases(res)
            })
            .catch((error) => {
                toast.error(error.msg)
            });
    }

    var currentDatetime = new Date(new Date().getTime() + 1 * 60 * 60 * 1000).toISOString()

    const [exportFilterValue, setExportFilterValue] = useState(null)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [exportedCases, setExportedCases] = useState([])
    
    const exportCases = () => {
        
        if((!fromDate && !toDate) || (fromDate && toDate)){
            document.getElementById("export_cases").innerHTML = "Exporting..."
            var request = {
                what: "exportCases",
                data: applyFilter(exportFilterValue),
                params: {}
            };
            if(fromDate) request.params.fromdate = fromDate
            if(toDate) request.params.todate = toDate
            
            makePostRequest(request)
                .then((response) => {
                    handleCloseModal("export")
                    document.getElementById("export_cases").innerHTML = "Export"
                    var cases = response.data.exportrecord
                    var formated_cases = cases.map((item, index) => ({
                        ...item,
                        sn: index + 1
                      }));
                    setExportedCases(formated_cases)
                })
                .catch((error) => {
                    toast.error(error.msg)
                    document.getElementById("export_cases").innerHTML = "Export"
                });
        }else {toast.error("Kindly provide the end date or clear the start date to proceed")}
    }

    useEffect(() => {
        if(exportedCases.length > 0) document.getElementById("exportCasesAsXLSX").click()// eslint-disable-next-line
    }, [exportedCases])

    const handleToDateChange = (event) => {
        if (fromDate > event.target.value) {
          toast.error("The end date cannot be less than the start date");
          setToDate(''); // Clear the "todate" input
        } else setToDate(event.target.value);
    };

    const handleSearch = (event) => {
        const value = event.target.value
        if(!value) setSearchValue(null)
    }

    const formatDateTime = (datetime) => {
        const dateString = datetime;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-GB', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit',
            minute:'2-digit',
            hour12: false 
        });
        return formattedDate.replace(/,/g, '');
    }
    
    const statusColor = (color) => {
        switch(color){
            case "Case Unassigned":
                return "pending";

            case "Case Closed":
                return "closed";

            default:
                return "ongoing"
        }
    }

    const row = cases.map((row, index) => {
        return (
            <tr key={index}>
                <td>{ currentPage === 1 ? currentPage + index : (((itemsPerPage*currentPage)-itemsPerPage)+1) + index }</td>
                <td>
                    <p className="link-text uppercase no-textwrap" 
                        title='View Case Activity Log' 
                        onClick={() => {updatePage("Case Activity Log"); setParams({id: row.case_id, caseno: row.caseno, origin: page_title})}}>{ row.caseno }</p>
                </td>
                <td>{ row.title }</td>
                <td>{ formatDateTime(row.createdAt) }</td>
                <td className="capitalize">{ row.assigned_users.map(user => user.user_name).join(', ') }</td>
                <td className="capitalize">{ row.classification }</td>
                <td className={statusColor(row.status)}>{row.status}</td>
                <td>
                    <div class="dropdown">
                        <button class="dropbtn">View<span className="material-symbols-rounded" style={{fontSize: "20px"}}>keyboard_arrow_down</span></button>
                        <div class="dropdown-content">
                            <button onClick={() => {updatePage("Case Details"); setParams({id: row.case_id, origin: page_title})}}>Case Details</button>
                            <button onClick={() => {handleOpenModal("progress");
                                setIsHoldsMerit(row.holdsmerit);
                                setStaffInvolved(row.staffinvolved);
                                setCaseStatusIndex( status.findIndex(item => item.label.toLowerCase() === row.status.toLowerCase()) )}}
                                >Case Progress</button>
                        </div>
                    </div>
                </td>
            </tr>
        )
    })

    useEffect(() => {
        if(ACCESS_TOKEN) getAllCases() 
        // eslint-disable-next-line
    }, [currentPage, itemsPerPage, searchValue, filterValue])

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-inner">
                    <div className="main-inner-top">
                        <div className="search-container left-item">
                            <span className="material-symbols-rounded">search</span>
                            <input title='Search by case id, title and classification' id="search" placeholder="Search Cases" onKeyUp={handleSearch} />
                        </div>
                        <button className="custom-button blue left-item mr-2" onClick={() => 
                            setSearchValue(document.getElementById("search").value)}>Search</button>
                        <div className='right-item'>
                            <select onChange={(event) => setFilterValue(event.target.value)}>
                                <option value="">All Cases</option>
                                <option value="unassigned">Unassigned Cases</option>
                                <option value="ongoing">Ongoing Cases</option>
                                <option value="closed">Closed Cases</option>
                            </select>
                        </div>
                        <button className="custom-button blue-outline ml-2" onClick={() => handleOpenModal("export")} >Export Cases</button>
                        <ExcelFile filename={'cases'+ currentDatetime +".csv"} 
                            element={<button id="exportCasesAsXLSX" hidden></button>}>
                            <ExcelSheet data={exportedCases} name="cases">
                                <ExcelColumn label="SN" value="sn" />                                
                                <ExcelColumn label="Case ID" value="case no" />                                
                                <ExcelColumn label="Title" value="title" />                                
                                <ExcelColumn label="Status" value="status" />                                
                                <ExcelColumn label="Sender" value="sender" />                                
                                <ExcelColumn label="Category" value="category" />                                
                                <ExcelColumn label="Holds Merit" value="holdsmerit" />                                
                                <ExcelColumn label="Staff Involved" value="staffinvolved" />                                
                                <ExcelColumn label="Classification" value="classification" />                                
                                <ExcelColumn label="District" value="district" />                                
                                <ExcelColumn label="Original Email Recipient" value="originalemailrecipient" />                                
                                <ExcelColumn label="Created At" value="createdat" />                                
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Case ID</th>
                                    <th>Case Title</th>
                                    <th>Date Recieved</th>
                                    <th>Investigator</th>
                                    <th>Classification</th>
                                    <th>Status</th>
                                    <th className="last-column">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? null : (
                                        cases.length === 0 ? <td className='no-records'>No Records Found</td>
                                        : row
                                    )
                                }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                <img src={loading} alt="loading" />
                                <p>Loading data...</p>
                            </div> : null}
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-group">
                            <div>
                                <select onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}>
                                    <option value="10">Show 10 Entries</option>
                                    <option value="25">Show 25 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                </select>
                                <p className="page-num">Page {currentPage} of {totalPages}</p>

                            </div>
                            <div className="pagination">
                                <button className="page-item" onClick={handlePrevious} disabled={currentPage === 1} >Previous</button>
                                {
                                    getPageNumbers()
                                        .slice(0, Math.min(3, getPageNumbers().length)) // Limit the range of the array to the first 2 elements
                                        .map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                                onClick={() => setCurrentPage(pageNumber)}
                                            >{pageNumber}</button>
                                        ))                                        
                                }
                                {getPageNumbers().length > 3 && <button className='page-item'>...</button>}

                                {getPageNumbers().length > 3 && 
                                <button
                                    className={`page-item ${currentPage === getPageNumbers().length ? 'active' : ''}`}    
                                >
                                    {getPageNumbers().length}
                                </button>}
                                <button className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    </div>  
                </div>                 
            </div>

            <Modal isOpen={isOpenCaseProgess} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Case Progress</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("progress")}>close</span>
                </div>
                <div className="modal-body status-progress">
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper orientation="vertical">
                        {/* <Stepper activeStep={activeStep} orientation="vertical"> */}
                            {status.map((step, index) => (
                            <Step key={step.label} completed={step.completed}>
                                <StepLabel
                                // optional={
                                //     index === 4 ? (
                                //     <Typography variant="caption">Last step</Typography>
                                //     ) : null
                                // } // Can be used to indicate a step was skipped.
                                >
                                {step.label}
                                </StepLabel>
                            </Step>
                            ))}
                        </Stepper>
                    </Box>
                </div>
            </Modal> 
            <Modal isOpen={isOpenExport} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Export Cases</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("export")}>close</span>
                </div>
                <div className="modal-body">
                    <label><span className="errorX mr-2">*</span>Filter Cases</label>
                    <select onChange={(event) => setExportFilterValue(event.target.value)}>
                        <option value="">All Cases</option>
                        <option value="unassigned">Unassigned Cases</option>
                        <option value="ongoing">Ongoing Cases</option>
                        <option value="closed">Closed Cases</option>
                    </select>
                    <div className="select-group mt-2">
                        <div>
                            <label>Start Date</label>
                            <input onChange={(event) => setFromDate(event.target.value)} type='date' name="fromdate" />
                        </div>
                        <div>
                            <label>{fromDate && <span className="errorX mr-2">*</span>}End Date</label>
                            <input 
                                value={toDate}
                                disabled={fromDate===null || fromDate===""} 
                                onChange={handleToDateChange}  
                                type='date' name="todate" />
                        </div>                    
                    </div>
                    <div className='modal-footer bt-1'>
                        <button id="export_cases" className='custom-button blue' onClick={() => exportCases()}>Export</button>
                    </div>
                </div>
            </Modal> 
            <ToastContainer />
        </> 
    )
}

export default CaseManagement