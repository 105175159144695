import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect } from "react";
import { ACCESS_TOKEN, makePostRequest, makePutRequest } from '../../request';
import Modal from 'react-modal';
import loading from "../../assets/images/loading.gif"
import HTMLContent from '../HTMLContent';
import { USERDETAILS } from '../../request';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
const filterOptions = createFilterOptions({ limit: 100 });

const CaseDetails = (props) => {
    const {page_title, updatePage, params} = props
    const [formData, setFormData] = useState({
        user_id: null,
        case_id: null,
        classification: "",
        district: "",
        originalemailrecipient: ""
    })
    const [isOpenDescription, setIsOpenDescription] = useState(false)
    const [isSendNotification, setIsSendNotification] = useState(false)
    const [isOpenChat, setIsOpenChat] = useState(false)
    const [isOpenFileUpload, setIsFileUpload] = useState(false)
    const [isOpenAssignCase, setIsOpenAsignCase] = useState(false)
    const [isOpenStaffInvolved, setIsOpenStaffInvolved] = useState(false)
    const [isLoadingPage, setIsLoadingPage] = useState(false)

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    }; 

    const handleOpenModal = (modal) => {
        switch(modal){
            case "description":
                setIsOpenDescription(true)
                break

            case "loading":
                setIsLoadingPage(true)
                break

            case "chat":
                setIsOpenChat(true)
                break

            case "file-upload":
                setIsFileUpload(true)
                break

            case "notify":
                setIsSendNotification(true)
                break

            case "assign-case":
                setIsOpenAsignCase(true)
                getAllInvestigators()
                break

            case "staffinvolved":
                setIsOpenStaffInvolved(true)
                break

            default:
                break
        }
    }

    const handleCloseModal = (modal) => {
        switch(modal){
            case "loading":
                setIsLoadingPage(false)
                break

            case "description":
                setIsOpenDescription(false)
                break

            case "chat":
                setIsOpenChat(false)
                break

            case "notify":
                setIsSendNotification(false)
                break

            case "file-upload":
                setIsFileUpload(false)
                setUploadData({
                                file: null,
                                type: null
                            })
                break

            case "assign-case":
                setIsOpenAsignCase(false)
                setFormData({
                    user_id: null,
                    case_id: null,
                    classification: "",
                    district: "",
                    originalemailrecipient: ""
                })
                setInvestigatorArray([])
                break

            case "staffinvolved":
                setIsOpenStaffInvolved(false)
                setCaseStatus("IC Review")
                setRadioData({
                    staffinvolved: null,
                    issubstantial: null
                })
                break

            default:
                break
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target //get data form each input on change
        setFormData(values => ({...formData, [name]: value})) //set retrieved values to "formData" object
    }

    const [casedetail, setCase] = useState([])
    const [caseReports, setCaseReports] = useState([])

    const getCase = () => {
        handleOpenModal("loading")
        var request = {
            what: "getAllCases",
            data: {
                pagination: {
                    skip: 0,
                    limit: 100
                },
                desired_statuses: [],
                         
            },
            params: {
                case_id: params.id
            } 
        };
        
        makePostRequest(request)
            .then((response) => {
                handleCloseModal("loading")
                // let res = response.data.cases.filter(item => item.case_id === params.id)[0]
                let res = response.data.cases[0]
                setCase(res)
                setCaseReports(res.reports)
            })
            .catch((error) => {
                toast.error(error.msg)
            });
    }

    const [investigators, setInvestigators] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const getAllInvestigators = () => {
        setIsLoading(true)
        var request = {
            what: "getAllInvestigators",
        };
        
        makePostRequest(request)
            .then((response) => {
                setIsLoading(false)
                const res = response.data.users
                setInvestigators(res)
            })
            .catch((error) => {
                toast.error(error.msg)
                setIsLoading(false)
            });
    }

    const [investigatorArray, setInvestigatorArray] = useState([]);
    const getInvestigatorsId = (event) => {
        const checkboxValue = event.target.value;
        if (event.target.checked) setInvestigatorArray([...investigatorArray, parseInt(checkboxValue)]);
        else setInvestigatorArray(investigatorArray.filter((value) => value !== checkboxValue));
    };

    const investigator_row = investigators.map((row, index) => {
        return (
            <tr key={index}>
                <td><input name="investigator" onChange={getInvestigatorsId} type="checkbox" value={row.id} /></td>
                <td className="capitalize">{row.firstname} {row.lastname}</td>
                <td>{row.emailaddress}</td>                                                            
                {/* <td>0</td>   */}
            </tr>
        )
    })

    // useEffect(() => {
    //     console.log(investigatorArray)
    // }, [investigatorArray])

    const formatDateTime = (datetime) => {
        const dateString = datetime;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-GB', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit',
            minute:'2-digit',
            hour12: false 
        });
        return formattedDate.replace(/,/g, '');;
    }

    const assignCase = () => {
        document.getElementById('assign_btn').innerHTML = 'Loading...'
        const data = {
            user_id: investigatorArray.length < 1 ? false : investigatorArray,
            case_id: casedetail.case_id,
            classification: formData.classification,
            district: formData.district,            
        }
        
        if (Object.values(data).every(value => value)){
            data.originalemailrecipient = formData.originalemailrecipient

            var request = {
                what: "assignCase",
                data: data
            };
            makePostRequest(request)
                .then((response) => {
                    document.getElementById('assign_btn').innerHTML = 'Submit'
                    handleCloseModal("assign-case")
                    toast.success(response.data.message)
                    getCase()
                })
                .catch((error) => {
                    toast.error(error.msg)
                });            
        }else {
            toast.warning("Please provide information for all required fields")
            document.getElementById('assign_btn').innerHTML = 'Submit'
        }
    }

    const downloadSupportingDocuments = (filenames, alertId) => {
        let prefix = "emailattachments/" + alertId + "/" // the base location for the files.
        if(filenames.includes(',')) {
            let files = filenames.split(',')
            files.map((file) => handleDownloadClick(prefix + file, file)) // adding the base location to the filename
        }else handleDownloadClick(prefix + filenames, filenames)
    }

    const handleDownloadClick = async (filename, file) => {
        try {
            // Fetch the file from the API
            const response = await fetch(process.env.REACT_APP_SERVER_URL+"/downloadreport?filename="+filename, {
                method: 'POST',
                headers: {
                    'Authorization':'Bearer ' + ACCESS_TOKEN
                }});

            const blob = await response.blob();
    
          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);
    
          // Create an anchor element
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = file; // Set the desired file name
    
          // Trigger the click event to initiate the download
          a.click();
    
          // Cleanup: Revoke the Blob URL
          URL.revokeObjectURL(blobUrl);
        } catch (error) { toast.error('Error downloading file:', error); }
    };

    const [caseComments, setCaseComments] = useState([])
    const [reportTypes] = useState([
        "Workplan Report", "Preliminary Investigation Report", "Full Investigation Report"
    ])
    const [type, setType] = useState(null)
    const row = caseReports.reverse().map((report) => (
        <tr key={report.id}>
          <td>{report.casetype}</td>
          <td>{formatDateTime(report.createdAt)}</td>
          <td className='capitalize'>
            {reportTypes.includes(report.casetype) && <span className={"status "+report.status}>{report.status}</span>}
            {report.casetype === "Sanction Implemented with Evidence" 
                && <span className={"status danger"}>Case Closed</span>}
            {!["Workplan Report", "Preliminary Investigation Report", "Full Investigation Report", "Sanction Implemented with Evidence"].includes(report.casetype)
                && <span className={"status info"}>Uploaded</span>}
          </td>
          <td className="actions">
              <p className="blue-text" onClick={() => handleDownloadClick(report.filename, report.casetype)}>Download Report</p>              
              {reportTypes.includes(report.casetype) && <span onClick={() => {handleOpenModal("chat"); setCaseComments(casedetail.case_comment); setType(report.casetype)}} className="material-symbols-rounded chat-button">forum</span>}
          </td>
        </tr>
    ))

    const [classifications, setClassifications] = useState([])
    const getAllClassifications = () => {
        setIsLoading(true)
        var request = {
            what: "getAllClassifications",
        };
        
        makePostRequest(request)
            .then((response) => {
                const res = response.data.classification
                setClassifications(res)
            })
            .catch((error) => toast.error(error.msg));
    }

    const row_classifications = classifications.map((row, index) => {
        return (
            <option key={index} value={row.Classifications}>{row.Classifications}</option>
        )
    })

    const copyToClipboard = (text) => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = text;
    
        // Make the textarea non-editable to avoid focus and activation
        tempTextArea.setAttribute("readonly", "");
        tempTextArea.style.position = "absolute";
        tempTextArea.style.left = "-9999px";
    
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
    
        try {
            // Copy the text to the clipboard
            document.execCommand("copy");
            toast.success("Email copied to clipboard: " + text);
        } catch (err) {
            toast.error("Unable to copy email to clipboard: " + err);
        } finally {
            document.body.removeChild(tempTextArea);
        }
    }

    const comments = caseComments.filter((comment)=>comment.commentype===type).map((row, index) => {
        return (
            <div key={index} className={USERDETAILS.emailaddress === row.userdetails.split(';')[2] ? 'chat-dialog right' : 'chat-dialog left'}>                    
                <div className='inner'>
                    <p className='title'>
                        <span className='capitalize'>{row.userdetails.split(';')[0]} {row.userdetails.split(';')[1]}</span> - <span className='uppercase'>{row.userdetails.split(';')[3]}</span></p>
                    <p className='link' onClick={() => copyToClipboard(row.userdetails.split(';')[2])}>Copy Email</p>
                </div> 
                <p className='body'>{row.comment}</p>
                <p className='date'>{formatDateTime(row.createdAt)}</p>
            </div>
        )
    })

    const checkStatus = (status) => {
        const enabledStatus = [
            "IC Review",
        ]

        // Check if the status is included in the enabledStatus array
        if (enabledStatus.includes(status)) return false
        else return true
    }

    const [radioData, setRadioData] = useState({
        staffinvolved: null,
        issubstantial: null
    })

    const handleRadioChange = (event) => {    
        const { name, value } = event.target
        setRadioData(values => ({...radioData, [name]: value}))
    };

    const validateInputs = () => {
        document.getElementById("submit").innerHTML = "Loading..."
        if(radioData.issubstantial) {
            if(radioData.issubstantial === "true" && radioData.staffinvolved !== null) updateCaseStatus(casedetail.case_id);
            else if(radioData.issubstantial === "true" && radioData.staffinvolved === null) toast.error("Kindly select the staff involved")
            else if(radioData.issubstantial === "false") updateCaseStatus(casedetail.case_id);
            else toast.error("Kindly provide the required fields")
        }else toast.error("Kindly indicate if the case is substantial")
    }

    const [caseStatus, setCaseStatus] = useState(null)
    const updateCaseStatus = (id) => {
        var status = caseStatus

        if(radioData.staffinvolved === 'tucn') status = "Case Transferred to PTM & DC"
        if(radioData.staffinvolved === 'contract') status = "Case Transferred to C & P"
        if(radioData.issubstantial === 'false') status = "Case Closed"

        if(status) {
            var request = {
                what: "updateCaseStatus",
                id: id,
                data: {
                    status: status
                }
            };     
            if(radioData.staffinvolved && radioData.issubstantial === "true") request.data.staffinvolved = radioData.staffinvolved
            if(radioData.issubstantial) request.data.issubstantial = radioData.issubstantial

            makePutRequest(request)
                .then((response) => {         
                    if(radioData.issubstantial) document.getElementById("submit").innerHTML = "Submit"
                    if(radioData.issubstantial) handleCloseModal("staffinvolved")           
                    toast.success(response.msg)
                    getCase() 
                })
                .catch((error) => {
                    toast.error(error.msg); 
                    if(radioData.issubstantial) document.getElementById("submit").innerHTML = "Submit"  
                })
        }else toast.warning("Please select the new status to update")
    }  
    
    const onFileChange = (event) => {
        // let selectedFile = event.target.files[0]
        // setUploadData(({...uploadData, file: selectedFile}))

        let selectedFile = event.target.files[0]

        let file = selectedFile.name.split(".");
        if (file[1] === "pdf" || file[1] === "doc" || file[1] === "docx" || file[1] === "pptx" 
        || file[1] === "ppt") setUploadData(({...uploadData, file: selectedFile}))
        else{
            document.getElementById("upload-report").value = ""
            toast.error("Attempted to upload an invalid file format. Please re-upload the correct file formats.")
        }
    }

    const [uploadData, setUploadData] = useState({
        file: null,
        type: null
    })
    const [caseId, setCaseId] = useState(null)
    const uploadReport = () => {
        
        if (uploadData.file){
            document.getElementById("upload_btn").innerHTML = 'Loading...'
            const form = new FormData()
            form.append("file", uploadData.file)
            form.append("casetype", uploadData.type)
            form.append("case_id", caseId)

            var request = {
                what: "uploadReport",
                data: form,
            };   

            makePostRequest(request)
                .then((response) => {                    
                    toast.success("Report Uploaded Successfully")
                    document.getElementById('upload_btn').innerHTML = 'Upload'
                    handleCloseModal('file-upload')  // close modal              
                    getCase() 
                    setUploadData({
                        file: null,
                        type: null
                    })
                        setCaseId(null)
                })
                .catch((error) => {toast.error(error.msg); document.getElementById('upload_btn').innerHTML = 'Upload' });
        }else toast.warning("Please provide the required file")
    }

    const sendNotification = () => {
        document.getElementById("notify-btn").innerHTML = 'Loading...'
        var request = {
            what: "sendNotification",
            id: casedetail.case_id
        };   

        makePostRequest(request)
            .then((response) => {                    
                toast.success("The initiator has been notified")
                document.getElementById('notify-btn').innerHTML = 'Send'
                handleCloseModal('notify')
            })
            .catch((error) => {toast.error(error.msg); document.getElementById('notify-btn').innerHTML = 'Send' });
    }

    useEffect(() => {
        if(radioData.issubstantial === "false") setRadioData(values => ({...radioData, staffinvolved: null})) // eslint-disable-next-line
    }, [radioData.issubstantial]) 

    useEffect(() => {
        if(caseStatus === "IC Review Completed") handleOpenModal("staffinvolved") // eslint-disable-next-line
    }, [caseStatus]) 

    const [usersList, setUsersList] = useState([])
    const getADUsers = () => {
        var request = {
            what: "userSearch",
            params: {
                records_per_page: 30000
            }
        };

        makePostRequest(request)
            .then((response) => {
                const res = response.data.all_records
                const formattedRecords = res.map((record) => {
                    return {label: record.FullName+" - "+record.Email, value: record.Email}
                })
                
                setUsersList(formattedRecords)
            })
            .catch((error) => toast.error(error.msg));
    }
    
    const handleOriginalRecipientEmail = (event, option) => {
        setFormData({...formData, 'originalemailrecipient': option.value});
    }

    useEffect(() => {
        if(ACCESS_TOKEN) {
            getCase()
            getADUsers()
        } // eslint-disable-next-line
    }, [])    

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-inner">
                    <div className="detail-top-section">
                        <p onClick={() => updatePage(params.origin)}><span className="material-symbols-rounded">arrow_back</span> Back to {params.origin}</p>
                        {casedetail.status === "Case Unassigned" &&
                            <button onClick={() => {handleOpenModal("assign-case"); getAllClassifications()}} className="custom-button blue">Assign Case</button>}
                            
                        {/* {(casedetail.holdsmerit === false || casedetail.issubstantial === false)&& */}
                        {casedetail.status === "Case Closed" && <div className='notify-button'>
                           <span className="material-symbols-rounded">notifications</span>
                            <p onClick={() => handleOpenModal("notify")}>Notify Initiator</p> 
                        </div>}
                    </div>
                    <div className="table-header">
                        <p>Case Information</p>
                        <button onClick={() => casedetail.filenames ? downloadSupportingDocuments(casedetail.filenames, casedetail.alertId) : toast.info("No Supporting Documents Available for this Case")}>Download Supporting Evidence(s)</button>
                    </div>
                    <div className="detail-table-section align-top">                        
                        <table>
                            <thead>
                                <tr>
                                    <th>Case ID</th>
                                    <th>Case Title</th>
                                    <th>Date Recieved</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="uppercase">{casedetail.caseno}</td>
                                    <td className='capitalize'>
                                        <p style={{marginTop: "0"}}>{casedetail.title}</p>
                                        <p className='view-link' onClick={() => handleOpenModal("description")}>View Incident Description</p>
                                    </td>                                    
                                    <td>{ formatDateTime(casedetail.createdAt)}</td>
                                    <td className="status-column">
                                        <select disabled={checkStatus(casedetail.status)}
                                            name="casestatus" value={caseStatus} onChange={(event) => setCaseStatus(event.target.value)}>
                                            <option key="0" disabled={casedetail.status} value={casedetail.status}>{casedetail.status}</option>
                                            {casedetail.status === "IC Review" && casedetail.holdsmerit && <option key="1" value="IC Review Completed">IC Review Completed</option>}
                                            {casedetail.status === "IC Review" && !casedetail.holdsmerit && <option key="2" value="Case Closed">Case Closed</option>}
                                        </select>
                                        <button 
                                            disabled={checkStatus(casedetail.status)} 
                                            onClick={() => updateCaseStatus(casedetail.case_id)} 
                                            className="custom-button blue">Update Status</button>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Sender of Mail</th>
                                    <th>Category</th>
                                    <th>Involved Parties</th> 
                                    <th></th>                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{casedetail.sender}</td>
                                    <td className='capitalize'>{casedetail.category}</td>                                                                        
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>             
                                    <th>Investigator(s)</th>
                                    <th>Classification</th>
                                    <th>Location</th> 
                                    <th>Original Email Recipient</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='capitalize'>{
                                        casedetail.assigned_users ? casedetail.assigned_users.map(user => user.user_name).join(', ') : ""}
                                    </td>
                                    <td className='capitalize'>{casedetail.classification}</td>
                                    <td className='capitalize'>{casedetail.district}</td>
                                    <td className='capitalize'>{casedetail.originalemailrecipient}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>                   

                    <div className="table-header">
                        <p>Manage Report & Uploads</p>
                        <div></div>
                    </div>
                    <div className="detail-table-section last-section custom">                        
                        <table>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Upload Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>                                
                                {caseReports.filter((report) => report.casetype==="Sanction Implemented with Evidence").length < 1
                                 && <tr>
                                    <td>Sanction Implemented with Evidence</td>
                                    <td></td>
                                    <td></td>
                                    <td className="action d-flx">
                                        <button 
                                            disabled={!(caseReports.filter((report) => report.casetype === "Validated Sanction from SLT").length > 0
                                            || casedetail.staffinvolved === "contract")}
                                            onClick={() => {handleOpenModal("file-upload"); 
                                            setCaseId(casedetail.case_id); setUploadData(({...uploadData, type: "Sanction Implemented with Evidence"})) }} className="custom-button blue mr-1">Upload File</button>
                                        
                                    </td>
                                </tr>}
                                {caseReports.filter((report) => report.casetype==="Validated Sanction from SLT").length < 1 && (casedetail.staffinvolved === null || casedetail.staffinvolved === "tucn")
                                 && <tr>
                                    <td>Validated Sanction from SLT</td>
                                    <td></td>
                                    <td></td>
                                    <td className="action d-flx">
                                        <button 
                                            disabled={caseReports.filter((report) => report.casetype === "Validated Sanction from IC").length < 1}
                                            onClick={() => {handleOpenModal("file-upload"); 
                                            setCaseId(casedetail.case_id); setUploadData(({...uploadData, type: "Validated Sanction from SLT"})) }} className="custom-button blue mr-1">Upload File</button>
                                        
                                    </td>
                                </tr>}
                                {caseReports.filter((report) => report.casetype==="Validated Sanction from IC").length < 1
                                 && <tr>
                                    <td>Validated Sanction from IC</td>
                                    <td></td>
                                    <td></td>
                                    <td className="action d-flx">
                                        <button
                                            disabled={!(caseReports.filter((report) => report.casetype === "Validated Sanction from DC").length > 0
                                            || casedetail.staffinvolved === "contract")}
                                            onClick={() => {handleOpenModal("file-upload"); 
                                            setCaseId(casedetail.case_id); setUploadData(({...uploadData, type: "Validated Sanction from IC"})) }} className="custom-button blue mr-1">Upload File</button>
                                       
                                    </td>
                                </tr>}
                                {caseReports.filter((report) => report.casetype==="Validated Sanction from DC").length < 1 && (casedetail.staffinvolved === null || casedetail.staffinvolved === "tucn")
                                 && <tr>
                                    <td>Validated Sanction from DC</td>
                                    <td></td>
                                    <td></td>
                                    <td className="action d-flx">
                                        <button 
                                            disabled={!(casedetail.status === "Case Transferred to C & P" || casedetail.status === "Case Transferred to PTM & DC")}
                                            onClick={() => {handleOpenModal("file-upload"); 
                                            setCaseId(casedetail.case_id); setUploadData(({...uploadData, type: "Validated Sanction from DC"})) }} className="custom-button blue mr-1">Upload File</button>
                                                
                                    </td>
                                </tr>}
                                {row}
                            </tbody>
                        </table>
                    </div>
                </div>                 
            </div>

            <Modal isOpen={isOpenDescription} style={customStyles} className="modal modal-4" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Incident Description</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("description")}>close</span>
                </div>
                <HTMLContent html={casedetail.body} />
            </Modal> 

            <Modal isOpen={isOpenChat} style={customStyles} className="modal modal-6" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Chat History</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("chat")}>close</span>
                </div>
                <div className='chat-container'>
                    {comments.length < 1 ? <span>No Comments</span> : comments}
                </div>
            </Modal>

            <Modal isOpen={isOpenFileUpload} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Upload File</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("file-upload")}>close</span>
                </div>
                <span className='small-text red'>Allowed Formats (.pdf, .doc, .docx, .ppt, .pptx, jpeg, jpg)</span>
                <input id='upload-report' name='file' onChange={onFileChange} type="file" />
                <div className="modal-footer bt-1">
                    <button id="upload_btn" type="submit" className="custom-button blue"
                        onClick={() => uploadReport()}>Upload</button>
                </div>
            </Modal>

            <Modal isOpen={isOpenAssignCase} style={customStyles} className="modal modal-5" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Assign Case</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("assign-case")}>close</span>
                </div>
                <div className="modal-body custom">
                    <div className="select-group">
                        <div>
                            <label><span className="errorX mr-2">*</span>Classification</label>
                            <select name='classification' value={formData.classification} onChange={handleChange}>
                                <option value="Select Classification"></option>
                                { row_classifications }
                            </select>
                        </div>
                        <div>
                            <label><span className="errorX mr-2">*</span>Location</label>
                            <select name="district" value={formData.district} onChange={handleChange} >
                                <option value="Select Location"></option>
                                <option value="abuja">Abuja</option>
                                <option value="lagos">Lagos</option>
                                <option value="port harcourt">Port Harcourt</option>
                                <option value="site">Site</option>
                            </select>
                        </div>                    
                    </div>
                    <div className="mt-1 mb-2">
                        <label>Original Mail Recipient</label>
                        {/* <input name='originalemailrecipient' value={formData.originalemailrecipient} onChange={handleChange} type="text" /> */}
                        <Autocomplete             
                            name="originalemailrecipient"                                          
                            filterOptions={filterOptions}                            
                            disableClearable={true}
                            onChange={(e, v) => handleOriginalRecipientEmail(e, v)}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            options={usersList}                            
                            className='mt-1'                            
                            renderInput={(params) => 
                                <TextField placeholder='Select...' variant='outlined' {...params} />} />
                    </div>
                    <label><span className="errorX mr-2">*</span>Select Investigator(s)</label>
                    <div className="investigators-table mt-1">                    
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Official Mail</th>
                                    {/* <th style={{textWrap: "nowrap"}}>Total Assigned Cases</th> */}
                                </tr>
                            </thead>
                            <tbody> 
                                {isLoading ? null : (
                                            investigators.length === 0 ? <td className='no-records'>No Records Found</td>
                                            : investigator_row
                                        )
                                    }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                    <img src={loading} alt="loading" className='sm' />
                                    <p>Loading data...</p>
                                </div> : null}
                    </div>
                </div>
                <div className="modal-footer bt-1">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("assign-case")}>Cancel</button>
                    <button id='assign_btn' type="submit" className="custom-button blue"
                        onClick={() => assignCase()}>Submit</button>
                </div>
            </Modal>

            <Modal isOpen={isLoadingPage} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                <div className="loader">
                    <img src={loading} alt="loading" />
                    <p>Loading data...</p>
                </div>
            </Modal> 

            <Modal isOpen={isOpenStaffInvolved} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Update Status</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("staffinvolved")}>close</span>
                </div>
                <div className="modal-body">
                    <label>Is it acceptable to proceed with this case?</label><br />
                    <input
                        name="issubstantial"
                        type="radio"
                        value={true}
                        onChange={handleRadioChange}
                    /> <label>Yes </label>

                    <br />
                    <input
                        name="issubstantial"
                        type="radio"
                        value={false}
                        onChange={handleRadioChange}
                    /> <label>No </label>

                    <br /><br />

                    {radioData.issubstantial === "true" && <>
                        <label>Select Staff Involved</label><br />
                        <input
                            name="staffinvolved"
                            type="radio"
                            value="tucn"
                            onChange={handleRadioChange}
                        /> <label>TUCN </label>

                        <br />
                        <input
                            name="staffinvolved"
                            type="radio"
                            value="contract"
                            onChange={handleRadioChange}
                        /> <label>Contract </label>
                    </>}
                </div>
                <div className="modal-footer bt-1">
                    <button id='submit' type="submit" className="custom-button blue"
                        onClick={() => validateInputs()}>Submit</button>
                </div>
            </Modal> 

            <Modal isOpen={isSendNotification} style={customStyles} className="modal modal-3" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Notification</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("notify")}>close</span>
                </div>
                <label className="text-lighter">
                The initiator of the case would recieve an email notification that the case is closed.
                </label>
                <div className="modal-footer bt-1">
                    <button className="custom-button grey-outline"
                        onClick={() => handleCloseModal("notify")}>Cancel</button>
                    <button id="notify-btn" type="submit" className="custom-button blue"
                        onClick={()=>sendNotification()}>Send</button>
                </div>
            </Modal>
            <ToastContainer />
        </> 
    )
}

export default CaseDetails