import image from "./assets/images/unauthorized.png"

const Unauthorized = () => {
    return (
        <>
            <div className="blocked-container">
                <div>
                    <img src={image} alt="unauthorized" width="50px" />
                    <h3>401 Unauthorized Access</h3>
                    <p>You do not have the permission to view this directory<br /> using the supplied credentials.</p>
                </div>
            </div>        
        </>
    )
}

export default Unauthorized