import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect } from "react";
import blue from "../../assets/images/blue.png"
import yellow from "../../assets/images/yellow.png"
import green from "../../assets/images/green.png"
import orange from "../../assets/images/orange.png"
import { ACCESS_TOKEN, makePostRequest } from '../../request';
import loading from "../../assets/images/loading.gif"

const Dashboard = (props) => {
    const {page_title, updatePage, setParams} = props

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); //limit
    const [totalItems, setTotalItems] = useState(null);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        return pageNumbers;
    };

    const [cases, setCases] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchValue, setSearchValue] = useState(null)
    const getAllCases = () => {
        setIsLoading(true)
        var request = {
            what: "getAllCases",
            data: { 
                pagination: {
                    skip: (currentPage - 1) * itemsPerPage,
                    limit: itemsPerPage
                },
                desired_statuses: ["Case Unassigned"]
            }
        };
        
        makePostRequest(request)
            .then((response) => {
                // console.log(searchValue)
                setIsLoading(false)
                const res = response.data.cases
                setTotalItems(response.data.total_records)
                if(searchValue) setCases(res.filter((item) => item.caseno.toLowerCase().includes(searchValue.toLowerCase()) ))
                    // || item.title.toLowerCase().includes(searchValue.toLowerCase())))
                else setCases(res)
            })
            .catch((error) => {
                toast.error(error.msg)
            });
    }
    const [dashboardCount, setDashboardCount] = useState([])
    const getDashboardCount = () => {
        setIsLoading(true)
        var request = {
            what: "dashboardDgm",            
        };
        
        makePostRequest(request)
            .then((response) => {
                setDashboardCount(response.data)
            })
            .catch((error) => {
                toast.error(error.msg)
            });
    }
    

    const handleSearch = (event) => {
        const value = event.target.value
        if(!value) setSearchValue(null)
    }

    const formatDateTime = (datetime) => {
        const dateString = datetime;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-GB', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit',
            minute:'2-digit',
            hour12: false 
        });
        return formattedDate.replace(/,/g, '');
    }
    
    // const statusColor = (color) => {
    //     switch(color){
    //         case "Case Unassigned":
    //             return "pending";

    //         case "Case Closed":
    //             return "closed";

    //         default:
    //             return "ongoing"
    //     }
    // }

    const row = cases.map((row, index) => {
        return (
            <tr key={index}>
                <td>{ currentPage === 1 ? currentPage + index : (((itemsPerPage*currentPage)-itemsPerPage)+1) + index }</td>
                <td className='no-textwrap'>{ row.caseno }</td>
                <td>{ row.title }</td>
                <td>{ row.sender }</td>
                <td>{ formatDateTime(row.createdAt) }</td>
                {/* <td>{ row.assigned_users.map(user => user.user_name).join(', ') }</td>
                <td className="capitalize">{ row.classification }</td> */}
                <td>
                    <button className='custom-button blue-outline' 
                    onClick={() => {updatePage("Case Details"); setParams({id: row.case_id, origin: page_title})}}>View</button>
                </td>
            </tr>
        )
    })

    useEffect(() => {
        if(ACCESS_TOKEN) {getAllCases(); getDashboardCount() }
        // eslint-disable-next-line
    }, [currentPage, itemsPerPage, searchValue])

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-top">
                    <div className="card">
                        <div className="card-content">
                            <img className="card-left" src={blue} alt="" />
                            <div className="card-right">
                                <h3>{dashboardCount.total_case_count}</h3>
                                <h5>Total Cases</h5>                                
                            </div> 
                        </div>                        
                    </div>
                    <div className="card">
                        <div className="card-content">
                            <img className="card-left" src={yellow} alt="" />
                            <div className="card-right">
                                <h3>{dashboardCount.total_open_case_count}</h3>
                                <h5>Total Open Cases</h5>                                
                            </div> 
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-content">
                            <img className="card-left" src={green} alt="" />
                            <div className="card-right">
                                <h3>{dashboardCount.total_closed_case_count}</h3>
                                <h5>Total Closed Cases</h5>                                
                            </div> 
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-content">
                            <img className="card-left" src={orange} alt="" />
                            <div className="card-right">
                                <h3>{dashboardCount.total_unassigned_case_count ?? 0}</h3>
                                <h5>Total Unassigned Cases</h5>                                
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="main-bottom">
                    <div className="main-inner-top">
                        <div className='left-item table-title'>Unassigned Cases</div>

                        <div className="search-container right-item">
                            <span className="material-symbols-rounded">search</span>
                            <input title='Saerch by case id, title' id="search" placeholder="Search Case ID" onKeyUp={handleSearch} />
                        </div>
                        <button className="custom-button blue" onClick={() => 
                            setSearchValue(document.getElementById("search").value)}>Search</button>
                    </div>
                    <div className="table-container custom">
                        <table>
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Case ID</th>
                                    <th>Case Title</th>
                                    <th>Sender of Mail</th>
                                    <th>Date Recieved</th>
                                    {/* <th>Investigator</th>
                                    <th>Classification</th> */}
                                    <th className="last-column">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? null : (
                                        cases.length === 0 ? <td className='no-records'>No Records Found</td>
                                        : row
                                    )
                                }
                            </tbody>
                        </table>
                        {isLoading ? <div className="loader">
                                <img src={loading} alt="loading" />
                                <p>Loading data...</p>
                            </div> : null}
                    </div>
                    <div className="pagination-container">
                        <div className="pagination-group">
                            <div>
                                <select onChange={(e) => {setItemsPerPage(e.target.value); setCurrentPage(1)}}>
                                    <option value="10">Show 10 Entries</option>
                                    <option value="25">Show 25 Entries</option>
                                    <option value="50">Show 50 Entries</option>
                                    <option value="100">Show 100 Entries</option>
                                </select>
                                <p className="page-num">Page {currentPage} of {totalPages}</p>

                            </div>
                            <div className="pagination">
                                <button className="page-item" onClick={handlePrevious} disabled={currentPage === 1} >Previous</button>
                                {
                                    getPageNumbers()
                                        .slice(0, Math.min(3, getPageNumbers().length)) // Limit the range of the array to the first 2 elements
                                        .map((pageNumber) => (
                                            <button
                                                key={pageNumber}
                                                className={`page-item ${pageNumber === currentPage ? 'active' : ''}`}
                                                onClick={() => setCurrentPage(pageNumber)}
                                            >{pageNumber}</button>
                                        ))                                        
                                }
                                {getPageNumbers().length > 3 && <button className='page-item'>...</button>}

                                {getPageNumbers().length > 3 && 
                                <button
                                    className={`page-item ${currentPage === getPageNumbers().length ? 'active' : ''}`}    
                                >
                                    {getPageNumbers().length}
                                </button>}
                                <button className="page-item" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>                 
            </div> 
            <ToastContainer />
        </> 
    )
}

export default Dashboard