import './App.css';
import Authentication from '../src/Authentication'
import Unauthentication from './Unauthorized'
import DeniedAccess from './DeniedAccess'
import Admin from './pages/Admin/Admin'
import DGM from './pages/DGM/DGM'
import Investigator from './pages/Investigator/Investigator'
import ValidateRole from './validateRole'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Logout from './LogoutUser';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Authentication />}/>
          <Route path="/error" element={<DeniedAccess />}/>
          <Route path="/unauthorized" element={<Unauthentication />}/>
          <Route path="/admin" element={<Admin />}/>
          <Route path="/dgm" element={<DGM />}/>
          <Route path="/investigator" element={<Investigator />}/>
          <Route path="/validaterole" element={<ValidateRole />}/>
          <Route path="/logout" element={<Logout />}/>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
