import { ToastContainer, toast } from 'react-toastify';
import logo from '../../src/assets/images/total-logo.png'
import { USERDETAILS, logoutTepngUser } from '../request';
import { useNavigate, Outlet } from "react-router-dom";
import AdminManualUrl from "../assets/media/AdminUserGuide.pdf"
import DgmManualUrl from "../assets/media/DgmUserGuide.pdf"
import InvestigatorManualUrl from "../assets/media/InvestigatorUserGuide.pdf"

const Header = () => {  
    const navigate = useNavigate();
    
    const logout = () => {
        logoutTepngUser().then(res => {
            if(res.status) {
                toast.success(res.msg) 
                localStorage.clear()
                navigate('/logout')
            } 
        }) 
    }

    const handleOpenFile = () => {   
        // Accessing the current window location
        const currentLocation = window.location.href;

        let result;

        // Check if "admin" is present in the current URL
        if(currentLocation.includes('admin')) result = "admin"
        if(currentLocation.includes('dgm')) result = "dgm"
        if(currentLocation.includes('investigator')) result = "investigator"

        switch(result){
            case "admin":
                window.open(AdminManualUrl, '_blank');
                break;
            
            case "dgm":
                window.open(DgmManualUrl, '_blank');
                break;

            case "investigator":
                window.open(InvestigatorManualUrl, '_blank');
                break;

            default:
                break;
        }
       
      };
    
    return (
        <>
            <nav>
                <div className='nav-left'>
                    <div className="menu_btn"><span className="material-symbols-rounded">menu</span></div>
                    <img width="105px" src={logo} alt=""/>
                </div>
               
                <div className='nav-right'>
                    <div>
                        <p className='capitalize'>{USERDETAILS ? USERDETAILS.firstname+" "+USERDETAILS.lastname : null}</p>
                        <p className='uppercase'>{USERDETAILS ? USERDETAILS.role : null}</p>
                    </div> 
                    <div className='user-manual'
                        title='Click to view user manual'
                        onClick={handleOpenFile}>
                        User Manual
                    </div>
                    <button className='custom-button red-outline' 
                        onClick={() => logout()}>Logout</button>
                </div>
            </nav>
            <ToastContainer />
            <Outlet />
        </>
    )
}

export default Header