import { toast, ToastContainer } from 'react-toastify';
import { useState, useEffect } from "react";
import { ACCESS_TOKEN, makePostRequest, makePutRequest, USERDETAILS } from '../../request';
import Modal from 'react-modal';
import loading from "../../assets/images/loading.gif"
import HTMLContent from '../HTMLContent';

const CaseDetails = (props) => {
    const {page_title, updatePage, params} = props
    const [isOpenDescription, setIsOpenDescription] = useState(false)
    const [isOpenChat, setIsOpenChat] = useState(false)
    const [isLoadingPage, setIsLoadingPage] = useState(false)
    const [caseStatus, setCaseStatus] = useState(null)

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    }; 

    const handleOpenModal = (modal) => {
        switch(modal){
            case "description":
                setIsOpenDescription(true)
                break

            case "loading":
                setIsLoadingPage(true)
                break

            case "chat":
                setIsOpenChat(true)
                break

            default:
                break
        }
    }

    const handleCloseModal = (modal) => {
        switch(modal){
            case "loading":
                setIsLoadingPage(false)
                break

            case "description":
                setIsOpenDescription(false)
                break

            case "chat":
                setIsOpenChat(false)
                break

            default:
                break
        }
    }

    // const handleChange = (event) => {
    //     const { name, value } = event.target //get data form each input on change
    //     setFormData(values => ({...formData, [name]: value})) //set retrieved values to "formData" object
    // }

    const [casedetail, setCase] = useState([])
    const [caseReports, setCaseReports] = useState([])
    const getCase = () => {
        handleOpenModal("loading")
        var request = {
            what: "getInvestigatorCases",
            data: { 
                pagination: {
                    skip: 0,
                    limit: 100
                },
                desired_statuses: []
            },
            params: {
                case_id: params.id
            }
        };
        
        makePostRequest(request)
            .then((response) => {
                handleCloseModal("loading")
                let res = response.data.cases.filter(item => item.case_id === params.id)[0]
                setCase(res)
                setCaseReports(res.reports)
            })
            .catch((error) => {
                toast.error(error.msg)
            });
    }

    const formatDateTime = (datetime) => {
        const dateString = datetime;
        const date = new Date(dateString);
        const formattedDate = date.toLocaleString('en-GB', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit',
            hour: '2-digit',
            minute:'2-digit',
            hour12: false 
        });
        return formattedDate.replace(/,/g, '');;
    }

    const checkStatus = (status) => {
        const enabledStatus = [
            "Case Assigned",
            "Awaiting Preliminary Investigation",
            "Awaiting Full Investigation",
        ]

        // Check if the status is included in the enabledStatus array
        if (enabledStatus.includes(status)) return false
        else return true
    }

    
    const updateCaseStatus = (id) => {
        if(caseStatus) {
            var request = {
                what: "updateCaseStatus",
                id: id,
                data: {
                    status: caseStatus
                }
            };             
            makePutRequest(request)
                .then((response) => {                    
                    toast.success("Case Status Updated Successfully")
                    getCase() 
                })
                .catch((error) => toast.error(error.msg))
        }else toast.warning("Please select the new status to update")
    }

    const downloadSupportingDocuments = (filenames, alertId) => {
        let prefix = "emailattachments/" + alertId + "/" // the base location for the files.
        if(filenames.includes(',')) {
            let files = filenames.split(',')
            files.map((file) => handleDownloadClick(prefix + file, file)) // adding the base location to the filename
        }else handleDownloadClick(prefix + filenames, filenames)
    }
    const handleDownloadClick = async (filename, file) => {
        try {
            // Fetch the file from the API
            const response = await fetch(process.env.REACT_APP_SERVER_URL+"/downloadreport?filename="+filename, {
                method: 'POST',
                headers: {
                    'Authorization':'Bearer ' + ACCESS_TOKEN
                }});

            const blob = await response.blob();
    
          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);
    
          // Create an anchor element
          const a = document.createElement('a');
          a.href = blobUrl;
          a.download = file; // Set the desired file name
    
          // Trigger the click event to initiate the download
          a.click();
    
          // Cleanup: Revoke the Blob URL
          URL.revokeObjectURL(blobUrl);
        } catch (error) { toast.error('Error downloading file:', error); }
    };

    const [caseComments, setCaseComments] = useState([])
    const [type, setType] = useState(null)
    const [reportTypes] = useState([
        "Workplan Report", "Preliminary Investigation Report", "Full Investigation Report"
    ])
    const row = caseReports.reverse().map((report) => (
        reportTypes.includes(report.casetype) && <tr key={report.id}>
          <td>{report.casetype}</td>
          <td>{formatDateTime(report.createdAt)}</td>
          <td className='capitalize'><span className={"status "+report.status}>{report.status}</span></td>
          <td className="actions">
              <p className="blue-text" onClick={() => handleDownloadClick(report.filename, report.casetype)}>Download Report</p>
              <span onClick={() => {handleOpenModal("chat"); setCaseComments(casedetail.case_comment); setType(report.casetype)}} className="material-symbols-rounded chat-button">forum</span>
          </td>
        </tr>
    ))

    const copyToClipboard = (text) => {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = text;
    
        // Make the textarea non-editable to avoid focus and activation
        tempTextArea.setAttribute("readonly", "");
        tempTextArea.style.position = "absolute";
        tempTextArea.style.left = "-9999px";
    
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
    
        try {
            // Copy the text to the clipboard
            document.execCommand("copy");
            toast.success("Email copied to clipboard: " + text);
        } catch (err) {
            toast.error("Unable to copy email to clipboard: " + err);
        } finally {
            document.body.removeChild(tempTextArea);
        }
    }

    const comments = caseComments.filter((comment)=>comment.commentype===type).map((row, index) => {
        return (
            <div key={index} className={USERDETAILS.emailaddress === row.userdetails.split(';')[2] ? 'chat-dialog right' : 'chat-dialog left'}>                    
                <div className='inner'>
                    <p className='title'><span className='capitalize'>{row.userdetails.split(';')[0]} {row.userdetails.split(';')[1]}</span> - <span className='uppercase'>{row.userdetails.split(';')[3]}</span></p>
                    <p className='link' onClick={() => copyToClipboard(row.userdetails.split(';')[2])}>Copy Email</p>
                </div> 
                <p className='body'>{row.comment}</p>
                <p className='date'>{formatDateTime(row.createdAt)}</p>
            </div>
        )
    })

    useEffect(() => {
        if(ACCESS_TOKEN) getCase()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="main">
                <h3 className="page_title">{ page_title }</h3>     
                <div className="main-inner">
                    <div className="detail-top-section">
                        <p onClick={() => updatePage(params.origin)}><span className="material-symbols-rounded">arrow_back</span> Back to {params.origin}</p>
                    </div>
                    <div className="table-header">
                        <p>Case Information</p>
                        <button onClick={() => casedetail.filenames ? downloadSupportingDocuments(casedetail.filenames, casedetail.alertId) : toast.info("No Supporting Documents Available for this Case")}>Download Supporting Evidence(s)</button>
                    </div>
                    <div className="detail-table-section align-top">                        
                        <table>
                            <thead>
                                <tr>
                                    <th className="uppercase">Case ID</th>
                                    <th>Case Title</th>
                                    <th>Date Recieved</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{casedetail.caseno}</td>
                                    <td className='capitalize'>
                                        <p style={{marginTop: "0"}}>{casedetail.title}</p>
                                        <p className='view-link' onClick={() => handleOpenModal("description")}>View Incident Description</p>
                                    </td> 
                                    <td>{ formatDateTime(casedetail.createdAt)}</td>
                                    <td className="status-column">
                                        <select disabled={checkStatus(casedetail.status)}
                                            name="casestatus" value={caseStatus} onChange={(event) => setCaseStatus(event.target.value)}>
                                            <option key="0" disabled={casedetail.status} value={casedetail.status}>{casedetail.status}</option>
                                            {casedetail.status === "Case Assigned" && <option key="1" value="Workplan Report Ongoing">Workplan Report Ongoing</option>}
                                            {casedetail.status === "Awaiting Preliminary Investigation" && <option key="2" value="Preliminary Investigation Ongoing">Preliminary Investigation Ongoing</option>}
                                            {casedetail.status === "Awaiting Full Investigation" && <option key="2" value="Full Investigation Ongoing">Full Investigation Ongoing</option>}
                                        </select>
                                        <button 
                                            disabled={checkStatus(casedetail.status)} onClick={() => updateCaseStatus(casedetail.case_id)} 
                                            className="custom-button blue">Update Status</button>
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Sender of Mail</th>
                                    <th>Category</th>
                                    <th>Involved Parties</th>
                                    <th></th>
                                </tr>                                
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{casedetail.sender}</td>
                                    <td className='capitalize'>{casedetail.category}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th>Investigator(s)</th>
                                    <th>Classification</th>
                                    <th>Location</th>                                    
                                    <th>Original Email Recipient</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='capitalize'>{casedetail.assigned_users ? casedetail.assigned_users.map(user => user.user_name).join(', ') : ""}</td>
                                    <td className='capitalize'>{casedetail.classification}</td>
                                    <td className='capitalize'>{casedetail.district}</td>                                    
                                    <td className='capitalize'>{casedetail.originalemailrecipient}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="table-header">
                        <p>Manage Reports</p>
                        <div></div>
                    </div>
                    <div className="detail-table-section custom">                        
                        <table>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Upload Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { row.length === 0 ? <td className='no-records'>No Records Found</td>
                                    : row }
                            </tbody>
                        </table>
                    </div>
                </div>                 
            </div>

            <Modal isOpen={isOpenDescription} style={customStyles} className="modal modal-4" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Incident Description</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("description")}>close</span>
                </div>
                <HTMLContent html={casedetail.body} />
            </Modal> 

            <Modal isOpen={isOpenChat} style={customStyles} className="modal modal-6" ariaHideApp={false}>
                <div className="modal-header">
                    <h3>Chat History</h3>
                    <span className="material-symbols-rounded close"
                        onClick={() => handleCloseModal("chat")}>close</span>
                </div>
                <div className='chat-container'>
                    {comments.length < 1 ? <span>No Comments</span> : comments}
                </div>          
            </Modal>

            <Modal isOpen={isLoadingPage} style={customStyles} className="modal modal-sm" ariaHideApp={false}>
                <div className="loader">
                    <img src={loading} alt="loading" />
                    <p>Loading data...</p>
                </div>
            </Modal> 
            <ToastContainer />
        </> 
    )
}

export default CaseDetails