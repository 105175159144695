import { useState } from 'react'
import logo from '../src/assets/images/logo.png'

import { useNavigate, Outlet } from "react-router-dom";
import Modal from "react-modal"
import { USERDETAILS } from './request';

const ValidateRole = () => {
    const navigate = useNavigate(); 
    const [openRolesModal] = useState(true);
    const [userRoles] = useState(USERDETAILS.role.split(','));

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }
    };

    return (
        <>
            <Modal isOpen={openRolesModal} style={customStyles} className="modal modal-2" ariaHideApp={false}>
                <div className='role-list'>
                    <img width="36px" src={logo} alt="totalenergies" />
                    <h4>Log into the Application As</h4>
                    <p>Select the role you wish to use to log in</p>

                    {userRoles !== null && userRoles.map((role, i) => {
                            return (
                                <div className='role-item' key={i}>
                                    <input type='radio' name='role' onChange={() => navigate("/"+role.trim())} />
                                    <span className='uppercase'>{role}</span>
                                </div>
                            )
                        })} 
                </div>
            </Modal>
            <Outlet />
        </>
    )
}

export default ValidateRole