

const HTMLContent =  (props) => {
    const { html } = props

    // return rawHTMl
    return (
        <div className="modal-body html-content" dangerouslySetInnerHTML={{ __html: html }} />
    )
}

export default HTMLContent