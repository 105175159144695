import image from "./assets/images/logout.png"

const LogoutUser = () => {
    return (
        <>
            <div className="blocked-container">
                <div>
                    <img src={image} alt="unauthorized" width="74px" />
                    <h3>You have logged out successfully.</h3>
                    <p>Click <a href="/">here</a> to log into the application</p>
                </div>
            </div>        
        </>
    )
}

export default LogoutUser